import { UrlService } from '@evasys/shared/core';
import { LanguageService } from '@evasys/evainsights/shared/core';
import { Document360Service, KnowledgeBaseService } from '@evasys/evainsights/shared/feature';
import { environment } from '@evasys/globals/evainsights/environments/environment';
import { firstValueFrom } from 'rxjs';

export function initUrlService(urlService: UrlService) {
	return () => urlService.initialize();
}

export function initLanguage(languageService: LanguageService) {
	return () => {
		languageService.initialize();
	};
}

export function initDocument360(document360Service: Document360Service, knowledgeBaseService: KnowledgeBaseService) {
	return () => {
		document360Service.initialize(environment.document360ApiKey, () =>
			firstValueFrom(knowledgeBaseService.getLoginToken())
		);
	};
}
