import { BrowserModule, HammerGestureConfig } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Hammer from 'hammerjs';
import { SharedCoreModule, UrlService } from '@evasys/shared/core';
import { SharedUtilModule } from '@evasys/shared/util';
import { SharedFeatureModule } from '@evasys/shared/feature';
import { SharedUiModule, TableModule } from '@evasys/shared/ui';
import { EvainsightsSharedUiModule } from '@evasys/evainsights/shared/ui';
import { HealthComponent } from './health/health.component';
import {
	DeveloperToolsModule,
	Document360Service,
	EvainsightsSharedFeatureModule,
} from '@evasys/evainsights/shared/feature';
import { FormsModule } from '@angular/forms';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { environment } from '@evasys/globals/evainsights/environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/fr';
import { globalReducer } from '../../../../libs/shared/core/src/lib/reducers/global.reducer';
import { initDocument360, initLanguage, initUrlService } from './app.init';
import { EvainsightsStoresCoreModule } from '@evasys/evainsights/stores/core';
import { EvasysRevokeTokenTypeEnum } from '@evasys/globals/shared/models/general/evasys-revoke-token-type.enum';
import { isApiUrl, PATH_AUTH_SERVICE, PATH_DATA_SERVICE } from '@evasys/globals/evainsights/api/endpoints';
import { ErrorHandlerService, MultiLangService, TranslocoMultiLangService } from '@evasys/evainsights/shared/util';
import { NgLetModule } from 'ng-let';
import { LanguageService } from '@evasys/evainsights/shared/core';

@Injectable({
	providedIn: 'root',
})
export class HammerConfig extends HammerGestureConfig {
	overrides = <any>{
		swipe: { direction: Hammer.DIRECTION_ALL },
	};
}

@NgModule({
	declarations: [AppComponent, HealthComponent],
	providers: [
		{ provide: APP_BASE_HREF, useValue: window['base-href'] },
		{
			provide: APP_INITIALIZER,
			useFactory: initUrlService,
			deps: [UrlService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initLanguage,
			deps: [LanguageService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initDocument360,
			deps: [Document360Service],
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		{
			provide: MultiLangService,
			useExisting: TranslocoMultiLangService,
		},
	],
	imports: [
		//#region AppModules
		AppRoutingModule,
		//#endregion AppModules
		//#region GeneralModules
		BrowserAnimationsModule,
		HttpClientModule,
		BrowserModule,
		NgLetModule,
		//#endregion GeneralModules
		//#region ReduxModules
		StoreModule.forRoot({}, { metaReducers: [globalReducer] }),
		StoreDevtoolsModule.instrument({
			maxAge: 50,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot(),
		//#endregion ReduxModules
		//#region SharedModules
		DeveloperToolsModule.forRoot(environment.enableDeveloperTools),
		TableModule.forRoot({
			translations: {
				tableHead: {
					sortAscending: 'common.sort.ascending',
					sortDescending: 'common.sort.descending',
				},
			},
		}),
		SharedUiModule.forRoot({
			translations: {
				usermenu: {
					changeRoleDropdownText: 'S_CHANGE_ROLE',
					logoutButtonText: 'common.logout',
					switchUserText: 'NONE',
					userMenuIconTitle: 'domain.userAccount',
				},
				searchHelp: {
					searchMenuButtonText: 'S_MENU_SEARCH_SINGLE',
					helpMenuButtonText: 'S_HELP',
					searchSubmitButtonAltText: 'S_MENU_SEARCH',
					searchTextboxLabel: 'S_SEARCH_SYSTEM_LABEL',
					helpTextboxLabel: 'S_SEARCH_ONLINEHELP_LABEL',
				},
				wizard: {
					errorMessages: {
						maxLength: 'NONE',
						min: 'NONE',
						max: 'NONE',
						minLength: 'NONE',
						emailType: 'NONE',
						required: 'NONE',
						equal: 'NONE',
						securePassword: 'NONE',
					},
					back: 'NONE',
					submit: 'NONE',
					cancel: 'NONE',
					next: 'NONE',
				},
				pagination: {
					nextButtonText: 'NONE',
					backButtonText: 'NONE',
				},
				uploadBox: {
					noFile: 'NONE',
					search: 'NONE',
				},
				sidebar: {
					permanentlyOpen: 'common.sidebar.permanentlyOpen',
				},
				systemUsage: {
					systemTranslation: {
						evasys: 'NONE',
						evaexam: 'NONE',
					},
					headlineTranslation: 'NONE',
					onlineUserTranslation: {
						evasys: 'NONE',
						evaexam: 'NONE',
					},
				},
				popperMenu: {
					showAll: 'NONE',
					noItemsFound: 'NONE',
				},
				productsMenu: {
					switchTo: 'common.switchToProduct',
					products: 'domain.products',
				},
				notificationCollection: {
					notificationIconTitle: 'domain.notification',
				},
				chipTypeahead: {
					selectAll: 'common.selectAll',
					removeAll: 'common.resetFilter',
					removeAllTooltip: 'common.resetFilter.tooltip',
				},
				legalNotice: {
					text: 'login.dataPrivacy.text',
					link: 'login.dataPrivacy.link',
				},
				showMore: {
					showMore: 'common.showMore',
				},
				typeahead: {
					loading: 'common.loading',
				},
			},
		}),
		EvainsightsSharedUiModule,
		SharedFeatureModule.forRoot({
			clientSecret: environment.clientSecret,
			isApiUrl,
		}),
		EvainsightsSharedFeatureModule,
		SharedCoreModule.forRoot({
			api: {
				auth: PATH_AUTH_SERVICE + 'login',
				user: PATH_DATA_SERVICE + 'staffs',
				translation: 'translate',
				config: 'configs',
				baseUrl: '',
				revokeTokenType: EvasysRevokeTokenTypeEnum.REFRESH_TOKEN,
			},
			auth: {
				clientId: environment.clientId,
				clientSecret: environment.clientSecret,
			},
			userMap: (apiData) => {
				const user = {
					...apiData,
					firstname: apiData.firstName,
				};
				delete user.firstName;
				return user;
			},
		}),
		EvainsightsStoresCoreModule,
		SharedUtilModule,
		//#endregion SharedModules
		//#region EvasysSharedModules
		FormsModule,
		TranslocoRootModule,
		BrowserAnimationsModule,
		NgbModule,
		//#endregion EvasysSharedModules
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
