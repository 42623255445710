import { InjectionToken } from '@angular/core';
import { appendElementHidden } from '@evasys/globals/evainsights/helper/dom';
import { assertNotNullish } from '@evasys/globals/shared/helper/typeguard';

export const CANVAS_RENDERING_CONTEXT_2D = new InjectionToken<CanvasRenderingContext2D>('CanvasRenderingContext2D', {
	providedIn: 'root',
	factory: () => {
		const canvas = document.createElement('canvas');
		canvas.width = 0;
		canvas.height = 0;
		appendElementHidden(canvas);

		const ctx = canvas.getContext('2d');
		assertNotNullish(ctx);
		return ctx;
	},
});
