import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ReportSearchRequest } from '@evasys/globals/evainsights/models/search/ReportSearchRequest';
import { Report, ReportPublishingInfo } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { PaginatedSearchRequest } from '@evasys/globals/evainsights/models/search/PaginatedSearchRequest';
import { ReportBaseService } from './reportBase.service';
import { ReportUpdate } from '@evasys/globals/evainsights/models/report/report-update.model';

@Injectable({
	providedIn: 'root',
})
export class ReportService implements ReportBaseService<Report, ReportSearchRequest> {
	readonly http = inject(HttpClient);

	getSearchResult(filter: ReportSearchRequest = {}): Observable<Page<Report>> {
		return this.http.post<Page<Report>>(endpoints.reportsSearch.url(), filter);
	}

	getById(reportId: number): Observable<Report> {
		return this.http.get<Report>(endpoints.report.url({ reportId }));
	}

	/**
	 * Returns the report the currently active share token grants access to.
	 *
	 * This method does not require any parameters because the access token is read directly from the URL by the
	 * `AccessTokenHeaderInterceptor` and subsequently added to all API requests via the `Authorization` header.
	 */
	getByToken(): Observable<Report> {
		return this.http.get<Report>(endpoints.reportByToken.url());
	}

	saveNew(report: Report): Observable<Report> {
		return this.http.post<Report>(endpoints.reports.url(), report);
	}

	update(_: Report): Observable<Report> {
		throw new Error('Update not implemented.');
	}

	publishReport(reportPublishing: ReportPublishingInfo): Observable<HttpResponse<unknown>> {
		return this.http.patch(endpoints.reportPublish.url({ reportId: reportPublishing.reportId }), reportPublishing, {
			observe: 'response',
		});
	}

	delete(report: Report): Observable<Report> {
		return this.http.delete<Report>(endpoints.report.url({ reportId: report.id }));
	}

	getReportSurveysForReportId(reportId: number, pagination: PaginatedSearchRequest): Observable<Page<Survey>> {
		return this.http.get<Page<Survey>>(endpoints.reportSurveys.url({ reportId }), { params: { ...pagination } });
	}

	putReportSurveys(reportId: number, surveyIds: number[]): Observable<Report> {
		return this.http.put<Report>(endpoints.reportSurveys.url({ reportId: reportId }), { surveyIds });
	}

	patch(reportId: number, reportUpdate: ReportUpdate): Observable<HttpResponse<unknown>> {
		return this.http.patch(endpoints.report.url({ reportId }), reportUpdate, {
			observe: 'response',
		});
	}

	calculateItems(reportId: number): Observable<Report> {
		return this.http.post<Report>(endpoints.reportCalculate.url({ reportId }), {});
	}

	putReportTemplate(reportId: number, reportTemplateId: number): Observable<Report> {
		return this.http.put<Report>(endpoints.reportReportTemplate.url({ reportId, reportTemplateId }), null);
	}

	removeReportTemplate(reportId: number, reportTemplateId: number): Observable<Report> {
		return this.http.delete<Report>(endpoints.reportReportTemplate.url({ reportId, reportTemplateId }));
	}
}
