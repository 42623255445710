import { catchError, filter, map, Observable, of, OperatorFunction, startWith, switchMap } from 'rxjs';

export interface LoadingState<T> {
	isLoading: boolean;
	data?: T;
	error?: any;
}

export const trackLoadingStates = () => {
	return <T>(requests: Observable<Observable<T>>): Observable<LoadingState<T>> => {
		return requests.pipe(switchMap((request) => request.pipe(trackLoadingState())));
	};
};

export const trackLoadingState = <T>(): OperatorFunction<T, LoadingState<T>> => {
	return (source: Observable<T>) =>
		source.pipe(
			map((data) => ({ isLoading: false, data })),
			catchError((error) => of({ isLoading: false, error })),
			startWith({ isLoading: true })
		);
};

export const latestData = () => {
	return <T>(states: Observable<LoadingState<T>>): Observable<T> => {
		return states.pipe(
			filter((state) => !state.isLoading && !('error' in state)),
			map((state) => state.data)
		);
	};
};
