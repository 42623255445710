import { inject, Injectable } from '@angular/core';
import { ChartExportFormat, getFormatInfo } from '@evasys/globals/evainsights/constants/data-types';
import JSZip from 'jszip';
import {
	downloadBlobContent,
	getFileNameAsString,
	makeFileNamesUnique,
	sanitizeFileName,
	SanitizeOptions,
} from '@evasys/globals/shared/helper/file';
import { zipShortest } from '@evasys/globals/shared/helper/array';
import { TranslocoService } from '@ngneat/transloco';
import { SvgRasterizationService } from './svg-rasterization.service';
import { assertNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({
	providedIn: 'root',
})
export class ChartExportService {
	translocoService = inject(TranslocoService);
	svgRasterizationService = inject(SvgRasterizationService);

	chartTitleDataAttribute = 'data-evainsights-chart-title';
	chartArchiveNameDataAttribute = 'data-evainsights-chart-archive-name';
	chartDataIsExportableAttribute = 'data-evainsights-chart-isExportable';

	public async exportAllCharts(format: ChartExportFormat) {
		const formatInfo = getFormatInfo(format);

		const archiveElement = document.querySelector(`[${this.chartArchiveNameDataAttribute}]`);
		assertNotNullish(archiveElement);
		const archiveName = archiveElement.getAttribute(this.chartArchiveNameDataAttribute);
		assertNotNullish(archiveName);

		const charts = this.findAllCharts(archiveElement);
		const fileNames = makeFileNamesUnique(
			charts.map((chart) =>
				sanitizeFileName({ stem: chart.title, extension: formatInfo.extension }, this.getChartSanitizeOptions())
			)
		).map(getFileNameAsString);

		const archive = new JSZip();
		for (const [name, { svg }] of zipShortest(fileNames, charts)) {
			archive.file(name, this.svgRasterizationService.rasterize(svg, formatInfo.mimeType));
		}
		const zipBlob = await archive.generateAsync({ type: 'blob' });
		downloadBlobContent(
			zipBlob,
			getFileNameAsString(
				sanitizeFileName({ stem: archiveName, extension: 'zip' }, this.getChartArchiveSanitizeOptions())
			)
		);
	}

	public async exportChart(parentElement: Element, format: ChartExportFormat) {
		const { title, svg } = this.findChart(parentElement);
		const formatInfo = getFormatInfo(format);
		this.svgRasterizationService.rasterize(svg, formatInfo.mimeType).then((dataBlob) => {
			const fileName = getFileNameAsString(
				sanitizeFileName({ stem: title, extension: formatInfo.extension }, this.getChartSanitizeOptions())
			);
			downloadBlobContent(dataBlob, fileName);
		});
	}

	private findChart(parentElement: Element) {
		const allCharts = this.findAllCharts(parentElement);
		if (allCharts.length === 0) {
			throw Error('Could not find a chart element');
		} else if (allCharts.length > 1) {
			throw Error('Found more than one chart element');
		}
		return allCharts[0];
	}

	private findAllCharts(parentElement: Element) {
		return Array.from(parentElement.querySelectorAll(`[${this.chartDataIsExportableAttribute}="true"]`)).map(
			(containerElement) => {
				const title = containerElement.getAttribute(this.chartTitleDataAttribute);
				assertNotNullish(title);

				const svg = containerElement.querySelector('svg');
				if (svg === null) {
					throw Error('Could not find svg element within the container element');
				}

				return { title, svg };
			}
		);
	}

	private getChartArchiveSanitizeOptions(): Partial<SanitizeOptions> {
		return {
			emptyStemFallback: this.translocoService.translate('reportItem.export.fallbackChartArchiveFileName'),
		};
	}

	private getChartSanitizeOptions(): Partial<SanitizeOptions> {
		return {
			emptyStemFallback: this.translocoService.translate('reportItem.export.fallbackChartFileName'),
		};
	}
}
