<ngb-highlight
	*ngIf="(text | hasText) || !(placeholder | hasText); else placeholderTemplate"
	[attr.data-cy]="'typeaheadFilterResult-' + id + '-text'"
	[result]="text"
	[term]="term"
	[title]="text"
	class="w-100 text-primary"
	evasysTextOverflow
></ngb-highlight>
<ng-template #placeholderTemplate
	><em class="text-primary" [attr.data-cy]="'typeaheadFilterResult-' + id + '-placeholder'">{{
		placeholder
	}}</em></ng-template
>
<div #container class="d-flex mw-100">
	<ng-container *ngFor="let contextInfo of contextInfos; let last = last; let i = index">
		<div
			[class]="contextInfoElementClass"
			[title]="contextInfo"
			[ngClass]="(i | modify : isContextInfoTruncated) ? 'text-truncate' : ''"
			[ngStyle]="contextInfoTruncatedWidths ? { 'max-width': contextInfoTruncatedWidths[i] + 'px' } : ''"
		>
			{{ contextInfo }}
		</div>
		<div [class]="dotClass" *ngIf="!last">{{ dotContent }}</div>
	</ng-container>
</div>
