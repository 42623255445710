import { sumBy } from 'lodash';
import { BaseType, select, Selection } from 'd3';
import { assertNotNullish } from '@evasys/globals/shared/helper/typeguard';

export interface AvoidOptions {
	bounds: Bounds;
	direction: AvoidDirection;
	padding?: number;
}

export enum AvoidDirection {
	HORIZONTAL,
	VERTICAL,
}

/**
 * Shift the selected elements vertically such that they avoid each other i.e. do not overlap
 */
export const avoid = <GElement extends SVGGraphicsElement, Datum, PElement extends BaseType, PDatum>(
	selection: Selection<GElement, Datum, PElement, PDatum>,
	{ bounds, direction, padding }: AvoidOptions
) => {
	const [axis, size] =
		direction === AvoidDirection.HORIZONTAL ? (['x', 'width'] as const) : (['y', 'height'] as const);

	determinePositions(
		(bounds[0] > bounds[1] ? selection.nodes() : selection.nodes().reverse())
			.map((node) => {
				const bbox = node.getBBox();
				return {
					item: {
						node,
						offset: Number(select(node).attr(axis)) - bbox[axis] + (padding ?? 0),
					},
					size: bbox[size] + 2 * (padding ?? 0),
					targetPosition: bbox[axis],
				};
			})
			.sort((a, b) => a.targetPosition - b.targetPosition),
		bounds[0] < bounds[1] ? bounds : [bounds[1], bounds[0]]
	).forEach(({ element, position }) => {
		select(element.item.node).attr(axis, position + element.item.offset);
	});
};

export const determinePositions = <T>(elements: Element<T>[], bounds: Bounds): ElementPosition<T>[] => {
	// One block = a group of elements that are touching and will thus move as one when pushed to either side.
	// The order in the blocks array is important: we always expect them to be in order of their position.
	// E.g. we say that blocks[0] = {start: 10, height: 5} intersects with blocks[1] = {start: 0, height: 5}
	// which would not be the case if they were ordered the other way around
	const blocks: Block<T>[] = [];

	// add the elements to the existing block configuration one by one
	// each loop makes sure that the placements given by the blocks is valid without any intersections
	for (const element of elements) {
		blocks.push(newBlock(element, bounds[1]));

		while (blocks.length >= 2 && doBlocksIntersect(blocks[blocks.length - 2], blocks[blocks.length - 1])) {
			/* eslint-disable @typescript-eslint/no-non-null-assertion */
			// blockY will be pushed to the start and blockZ to the end unless it already touches the bound
			const blockZ = blocks.pop()!;
			const blockY = blocks.pop()!;
			/* eslint-enable */

			const overlap = getOverlap(blockY, blockZ);
			const blockZDistanceToBound = bounds[1] - blockZ.height - blockZ.start;
			if (blockZDistanceToBound <= 0) {
				// blockZ touches the bound => push blockY such that it just touches blockZ and merge them
				blocks.push(mergeAdjacentBlocks(pushBlock(blockY, -overlap), blockZ));
			} else {
				// determine the push ratio between blockY and blockZ based on the block element count considering the
				// individual element distances to their target position
				const pushY = getBlockPushDetails(blockY, 'start');
				const pushZ = getBlockPushDetails(blockZ, 'end');
				const pushFactorZ = 1 + pushZ.force / pushY.force;
				const pushFactorY = 1 + pushY.force / pushZ.force;

				const blockX = blocks.pop();

				const pushDistances = {
					startContact: blockX
						? pushFactorY * (blockY.start - blockX.start - blockX.height)
						: Number.POSITIVE_INFINITY,
					full: overlap,
					endtContact: pushFactorZ * blockZDistanceToBound,
					blockYElementTarget: pushFactorY * (pushY.distance ?? Number.POSITIVE_INFINITY),
					blockZElementTarget: pushFactorZ * (pushZ.distance ?? Number.POSITIVE_INFINITY),
				};

				const pushLimitCriterion = objectArgmin(pushDistances);
				const pushDistance = pushDistances[pushLimitCriterion];

				const totalForce = pushY.force + pushZ.force;
				const pushedBlockY = pushBlock(blockY, (-pushDistance * pushZ.force) / totalForce);
				const pushedBlockZ = pushBlock(blockZ, (pushDistance * pushY.force) / totalForce);

				if (pushLimitCriterion === 'startContact') {
					assertNotNullish(blockX);
					blocks.push(mergeAdjacentBlocks(blockX, pushedBlockY, pushedBlockZ));
				} else {
					if (blockX !== undefined) {
						blocks.push(blockX);
					}

					if (pushLimitCriterion === 'full') {
						blocks.push(mergeAdjacentBlocks(pushedBlockY, pushedBlockZ));
					} else {
						blocks.push(pushedBlockY);
						blocks.push(pushedBlockZ);
					}
				}
			}
		}

		if (blocks[0].start < bounds[0]) {
			// after inserting the last element, the first block is now out of bounds
			// blocks are only moved when they collide
			blocks[0].start = bounds[0];
		}
	}

	return blocks.flatMap(
		(block) =>
			block.elements.reduce(
				(acc, element) => ({
					elementOffset: acc.elementOffset + element.size,
					result: [...acc.result, { element, position: block.start + acc.elementOffset }],
				}),
				{ elementOffset: 0, result: [] as ElementPosition<T>[] }
			).result
	);
};

const newBlock = <T>(element: Element<T>, rightBound: number): Block<T> => ({
	start: Math.min(element.targetPosition, rightBound - element.size),
	height: element.size,
	elements: [element],
});

const pushBlock = <T>(block: Block<T>, distance: number): Block<T> => ({
	...block,
	start: block.start + distance,
});

const mergeAdjacentBlocks = <T>(...blocks: Block<T>[]): Block<T> => ({
	start: blocks[0].start,
	height: sumBy(blocks, (block) => block.height),
	elements: blocks.flatMap((block) => block.elements),
});

const getOverlap = (a: Block<unknown>, b: Block<unknown>) => Math.max(0, a.start + a.height - b.start);

const doBlocksIntersect = (a: Block<unknown>, b: Block<unknown>) => getOverlap(a, b) > 0;

const getBlockPushDetails = (block: Block<unknown>, direction: 'start' | 'end') => {
	// how many elements in the block have their target position in the opposite of the push direction?
	let nOpposingElements = 0;
	// how far can we push the block until the nOpposingElements changes?
	let lowestDistanceToTarget: number | null = null;

	let position = block.start;
	for (const element of block.elements) {
		const distanceToTarget =
			direction === 'start' ? position - element.targetPosition : element.targetPosition - position;
		if (distanceToTarget >= 0) {
			nOpposingElements += 1;
		}

		if (distanceToTarget > 0) {
			if (lowestDistanceToTarget === null) {
				lowestDistanceToTarget = distanceToTarget;
			} else {
				lowestDistanceToTarget = Math.min(lowestDistanceToTarget, distanceToTarget);
			}
		}

		position += element.size;
	}

	return {
		distance: lowestDistanceToTarget,
		force: nOpposingElements,
	};
};

interface Block<T> {
	start: number;
	height: number;
	elements: Element<T>[];
}

export interface Element<T> {
	item: T;
	targetPosition: number;
	size: number;
}

export interface ElementPosition<T> {
	element: Element<T>;
	position: number;
}

export type Bounds = [number, number];

export const isBounds = (value: unknown): value is Bounds =>
	Array.isArray(value) && value.length === 2 && value.every((v) => typeof v === 'number');

const objectArgmin = <T extends { [key: string]: number }>(obj: T): keyof T => {
	let minKey: keyof T | undefined = undefined;
	for (const [key, value] of Object.entries(obj)) {
		if (minKey === undefined || value < obj[minKey]) {
			minKey = key;
		}
	}

	if (minKey === undefined) {
		throw Error('Unexpected empty object');
	}

	return minKey;
};
