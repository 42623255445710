import { Environment } from './environment.model';
import { Deployment } from './deployment.enum';
import { environment as sharedEnvironment } from './environment.shared';

export const environment: Environment = {
	...sharedEnvironment,
	production: true,
	dataServiceUrl: '/api/data/',
	reportServiceUrl: '/api/report/',
	authServiceUrl: '/api/auth/',
	applicationInsightsConnectionString: {
		[Deployment.LOCAL]: null,
		[Deployment.DE]:
			'InstrumentationKey=f74c0eba-8856-4eb6-8193-df1e97d12c4d;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/',
		[Deployment.STAGING_DE]:
			'InstrumentationKey=be386e96-46ff-491f-af4b-663eaaa3ca3d;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/',
		[Deployment.DEV_DE]:
			'InstrumentationKey=a4ebfc07-54a0-409b-aa54-0d8c9c7e6320;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/',
	},
};
