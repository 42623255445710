import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { EvasysEffects } from '../essential/redux/effects/evasys.effects';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { sharedActions } from '../actions/index';
import { SharedCoreConfiguration } from '../shared-core.configuration';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { ApiService } from '../essential/api/services/api.service';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';

@Injectable({
	providedIn: 'root',
})
export class AuthUserEffects extends EvasysEffects<EvasysUserModel | ExternalIdUserModel> {
	constructor(
		protected override actions$: Actions,
		protected readonly apiService: ApiService,
		public readonly sharedCoreConfiguration: SharedCoreConfiguration
	) {
		super(sharedActions.fromAuthUser, sharedCoreConfiguration.api.users, actions$);
	}

	authenticateUser$ = this.createEffect(
		sharedActions.fromAuthUser.AuthenticateUserActionSet,
		(action: any) =>
			({
				apiPath: this.sharedCoreConfiguration.api.user(action.payload),
				many: false,
				requestMethod: EvasysRequestMethodEnum.GET,
			} as EvasysApiRequestModel)
	);

	switchUser$ = this.createEffect(
		sharedActions.fromAuthUser.GetUserSwitchDataActionSet,
		() =>
			({
				apiPath: this.sharedCoreConfiguration.api.users,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				params: [['action', 'loginSwitchUser']],
			} as EvasysApiRequestModel)
	);

	protected mapToFrontend = (data: any) => this.sharedCoreConfiguration.userMap(data);

	protected mapToBackend(user: EvasysUserModel): any {
		return {
			...user,
		};
	}
}
