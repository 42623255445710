import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Document360SsoLoginTokenResponse } from '@evasys/globals/evainsights/models/knowledge-base/document360-login-token-response.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class KnowledgeBaseService {
	private readonly httpClient = inject(HttpClient);

	readonly getLoginToken = (): Observable<Document360SsoLoginTokenResponse> =>
		this.httpClient.post<Document360SsoLoginTokenResponse>(endpoints.knowledgeBaseLoginToken.url(), null);
}
