import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { SurveySearchRequest } from '@evasys/globals/evainsights/models/search/SurveySearchRequest';

@Injectable({
	providedIn: 'root',
})
export class SurveyService {
	private http = inject(HttpClient);

	getSearchResult(filter: SurveySearchRequest = {}): Observable<Page<Survey>> {
		return this.http.post<Page<Survey>>(endpoints.surveysSearch.url(), filter);
	}
}
