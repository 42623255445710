import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageDeveloperToolsComponent } from './components/page-developer-tools/page-developer-tools.component';
import { AreaDeveloperLoginComponent } from './components/area-developer-login/area-developer-login.component';
import { DomInjectorService } from './services/dom-injector.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule, CheckboxModule, DropdownModule, SelectorModule, TextboxModule } from '@evasys/shared/ui';
import { AreaUiConfigComponent } from './components/area-ui-config/area-ui-config.component';
import { EvainsightsSharedUtilModule } from '@evasys/evainsights/shared/util';
import { SharedUtilModule } from '@evasys/shared/util';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class ProductionToolsModule {}

@NgModule({
	declarations: [PageDeveloperToolsComponent, AreaDeveloperLoginComponent, AreaUiConfigComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		TranslocoModule,
		TextboxModule,
		ButtonModule,
		DropdownModule,
		EvainsightsSharedUtilModule,
		SelectorModule,
		SharedUtilModule,
		CheckboxModule,
	],
	providers: [DomInjectorService],
})
export class DeveloperToolsModule {
	_domInjectorService = inject(DomInjectorService);
	static forRoot(enabledDeveloperTools: boolean): ModuleWithProviders<any> {
		return enabledDeveloperTools
			? {
					ngModule: DeveloperToolsModule,
					providers: [],
			  }
			: {
					ngModule: ProductionToolsModule,
					providers: [],
			  };
	}
}
