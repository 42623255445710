import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { document360WidgetScriptSrc } from '@evasys/globals/evainsights/constants/document360';
import { Document360SsoLoginTokenResponse } from '@evasys/globals/evainsights/models/knowledge-base/document360-login-token-response.model';

const widgetName = 'mw';

@Injectable({
	providedIn: 'root',
})
export class Document360Service {
	document = inject(DOCUMENT);

	initialize(apiKey: string, getLoginToken: () => Promise<Document360SsoLoginTokenResponse>) {
		window['JS-Widget'] = widgetName;
		window[widgetName] =
			window[widgetName] ||
			function (...args: unknown[]) {
				window[widgetName].q = window[widgetName].q || [];
				window[widgetName].q.push(args);
			};

		const scriptElement = this.document.createElement('script');
		scriptElement.id = widgetName;
		scriptElement.src = document360WidgetScriptSrc;
		scriptElement.async = true;

		this.document.head.append(scriptElement);

		window.getDocument360LoginToken = getLoginToken;
		window[widgetName]('init', { apiKey });
	}

	toggleWidget() {
		const widgetIframe = document.getElementById('document360-widget-iframe');
		if (!(widgetIframe instanceof HTMLIFrameElement)) return;

		widgetIframe.contentDocument?.getElementById('doc360-button')?.click();
	}
}

declare global {
	interface Window {
		'JS-Widget': typeof widgetName;
		[widgetName]?: any;
		getDocument360LoginToken: () => Promise<Document360SsoLoginTokenResponse>;
	}
}
