import {
	AfterViewInit,
	Component,
	ComponentRef,
	ElementRef,
	forwardRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	filter,
	first,
	fromEvent,
	map,
	Observable,
	of,
	sample,
	scan,
	startWith,
	Subject,
	Subscription,
	switchMap,
	tap,
} from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
	exhaustScan,
	Formatter,
	ObservableFormatter,
	raisingFormatter,
	toObservableFormatter,
} from '@evasys/shared/util';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadingState, trackLoadingState } from '@evasys/evainsights/shared/util';
import { DOCUMENT } from '@angular/common';
import { isNotNullish, isNullish } from '@evasys/globals/evainsights/typeguards/common';
import { Options } from '@popperjs/core';
import { maxSizePopperOptions } from '../../../../../popover/max-size/popper-options';
import { minWidthReference } from '../../../../../popover/min-width';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { TypeaheadPopoverService } from '../../../../../services/typeahead-popover.service';
import { InputValueReplaceStrategy } from '@evasys/globals/shared/enums/component/typeahead.enum';
import {
	TypeaheadIdentifierValue,
	TypeaheadItemIdentifier,
	TypeaheadItems,
	TypeaheadItemSearch,
	TypeaheadItemSearchPage,
	TypeaheadStaticItems,
} from '@evasys/globals/shared/models/component/typeahead/typeahead.model';
import { SharedUiConfiguration } from '../../../../../shared-ui.configuration';
import { getTypeaheadIdentifierValue } from '@evasys/globals/shared/helper/typeahead';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-typeahead',
	templateUrl: './typeahead.component.html',
	styleUrls: ['./typeahead.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TypeaheadComponent),
			multi: true,
		},
	],
})
export class TypeaheadComponent<T> implements OnChanges, OnInit, AfterViewInit, OnDestroy {
	@Input()
	@Required()
	id!: string;

	@Input()
	@Required()
	set items(items: TypeaheadItems<T>) {
		this.items$.next(items);
	}

	@Input()
	@Required()
	set formatter(formatter: Formatter<T>) {
		this.formatter$.next(toObservableFormatter(formatter));
	}

	@Input()
	@Required()
	searchFailedText!: string;

	@Input()
	@Required()
	emptyResultsText!: string;

	@Input()
	resultTemplate!: TemplateRef<ResultTemplateContext<T>>;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	autoClosePopoverOnSelect = true;

	@Input()
	disabled = false;

	@Input()
	disableParentItems = false;

	@Input()
	itemIdentifier: TypeaheadItemIdentifier<T> = <TypeaheadItemIdentifier<T>>'id';

	@Input()
	autoFocus = false;

	@Input()
	placement = 'bottom-start bottom-end top-start top-end';

	@Input()
	header: TemplateRef<unknown>;

	@Input()
	popoverClass: string;

	@Input()
	childItemIdentifier?: string;

	@Input()
	popoverContainer?: 'body' = undefined;

	@Input()
	inputValueReplaceStrategy = InputValueReplaceStrategy.REPLACE_WITH_SELECTED_VALUE;

	@ViewChild('inputContainer')
	inputContainer!: ElementRef<HTMLDivElement>;

	@ViewChild('input')
	input!: ElementRef<HTMLInputElement>;

	@ViewChild('popover')
	popover!: NgbPopover;

	isOpen$ = new BehaviorSubject(false);
	searchText$ = new BehaviorSubject('');
	items$ = new BehaviorSubject<TypeaheadItems<T> | null>(null);
	formatter$ = new BehaviorSubject<ObservableFormatter<T>>(raisingFormatter);
	selected$ = new BehaviorSubject<T | null>(null);
	loadMore$ = new Subject<void>();
	search$ = combineLatest([this.items$.pipe(filter(isNotNullish)), this.formatter$]).pipe(
		map(([items, formatter]) => buildInfiniteScrollingSearch(items, formatter, this.loadMore$))
	);
	results$ = new BehaviorSubject<TypeaheadSearchState<T>>({
		isLoadingNewQuery: true,
		isLoadingMore: false,
	});
	activeIndex = 0;
	activeChildIndex = -1;
	escapeKeySubscription: Subscription | undefined = undefined;
	resultsSubscription: Subscription | undefined = undefined;
	subscriptions: Subscription[] = [];
	inputIntersectionObserver?: IntersectionObserver;
	borderAvoidingPopperOptions = maxSizePopperOptions({
		minWidth: 30,
		minHeight: 30,
		padding: { top: 60, left: 10, bottom: 10, right: 10 },
	});
	placeholder = ' ';

	clearSearchText() {
		this.searchText$.next('');
	}

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private typeaheadPopoverService: TypeaheadPopoverService,
		protected readonly config: SharedUiConfiguration
	) {}

	get minActiveChildIndex() {
		return this.disableParentItems ? 0 : -1;
	}

	onSearchTextChange(value: string) {
		this.open();
		this.searchText$.next(value);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['disableParentItems'] && this.disableParentItems) {
			this.activeChildIndex = Math.min(0, this.minActiveChildIndex);
		}
	}

	ngOnInit() {
		this.resultsSubscription = this.results$.subscribe(() => {
			this.activeIndex = 0;
			this.activeChildIndex = this.minActiveChildIndex;
		});

		this.subscriptions.push(
			combineLatest([this.searchText$.pipe(debounceTime(200), startWith(this.searchText$.value)), this.isOpen$])
				.pipe(
					filter(([, isOpen]) => isOpen),
					map(([text]) => text),
					distinctUntilChanged(),
					this.applySearch()
				)
				.subscribe(this.results$)
		);

		if (this.inputValueReplaceStrategy === InputValueReplaceStrategy.REPLACE_WITH_SELECTED_VALUE) {
			this.subscriptions.push(
				combineLatest([this.formatter$, this.selected$])
					.pipe(switchMap(([formatter, selected]) => (isNullish(selected) ? of('') : formatter(selected))))
					.subscribe((formattedItem) => {
						this.clearSearchText();
						this.placeholder = formattedItem !== '' ? formattedItem : ' ';
					})
			);
		}
	}

	ngAfterViewInit() {
		if (this.autoFocus) {
			this.input.nativeElement.focus();
		}
		this.escapeKeySubscription = this.isOpen$
			.pipe(
				filter((isOpen) => isOpen),
				sample(
					fromEvent<KeyboardEvent>(this.document, 'keydown').pipe(
						filter((event) => event.key === 'Escape'),
						tap((event) => {
							event.preventDefault();
							event.stopPropagation();
						})
					)
				)
			)
			.subscribe(() => this.close());

		this.inputIntersectionObserver = new IntersectionObserver(([entry]) => {
			if (!entry.isIntersecting) {
				this.close();
			}
		});
		this.inputIntersectionObserver.observe(this.input.nativeElement);
	}

	ngOnDestroy() {
		this.inputIntersectionObserver.disconnect();

		for (const sub of [this.escapeKeySubscription, this.resultsSubscription, ...this.subscriptions]) {
			if (sub) {
				sub.unsubscribe();
			}
		}
	}

	onScrolled() {
		this.loadMore$.next();
	}

	onEscapeKeydown(event: KeyboardEvent) {
		if (this.isOpen$.value) {
			event.stopPropagation();
			this.close();
		}
	}

	onArrowDownKeydown(event: KeyboardEvent) {
		event.preventDefault();

		if (!this.isOpen$.value) {
			this.open();
		} else if (this.isReady) {
			// set next active
			if (
				this.results$.value.latestResults.entities[this.activeIndex][this.childItemIdentifier]?.length - 1 >
				this.activeChildIndex
			) {
				this.activeChildIndex += 1;
			} else {
				const nextIndex = this.findNextSelectableIndex();
				if (nextIndex !== null) {
					this.activeIndex = nextIndex;
					this.activeChildIndex = this.minActiveChildIndex;
				}
			}
		}
	}

	onArrowUpKeydown(event: KeyboardEvent) {
		event.preventDefault();

		if (!this.isOpen$.value) {
			this.open();
		} else if (this.isReady) {
			// set previous active
			if (this.activeChildIndex > this.minActiveChildIndex) {
				this.activeChildIndex -= 1;
			} else {
				const previousIndex = this.findPreviousSelectableIndex();

				if (previousIndex !== null) {
					this.activeIndex = previousIndex;
					const newActiveEntity = this.results$.value.latestResults.entities[this.activeIndex];
					this.activeChildIndex = this.childItemIdentifier
						? newActiveEntity[this.childItemIdentifier].length - 1
						: -1;
				}
			}
		}
	}

	findPreviousSelectableIndex(): number | null {
		for (let i = this.activeIndex - 1; i >= 0; i--) {
			if (this.isSelectable(i)) {
				return i;
			}
		}

		return null;
	}

	findNextSelectableIndex(): number | null {
		const entityCount = this.results$.value.latestResults.entities.length;
		for (let i = this.activeIndex + 1; i < entityCount; i++) {
			if (this.isSelectable(i)) {
				return i;
			}
		}

		return null;
	}

	isSelectable(index: number): boolean {
		const entities = this.results$.value.latestResults.entities;
		if (index < 0 || index >= entities.length) {
			return false;
		}

		const entity = entities[index];
		return !this.disableParentItems || entity[this.childItemIdentifier].length > 0;
	}

	onConfirmKeydown(event: KeyboardEvent) {
		if (!this.isOpen$.value) {
			return;
		}

		event.preventDefault();

		const entities = this.results$.value?.latestResults.entities;
		const activeExists = entities !== undefined && entities.length > this.activeIndex;

		// select active
		if (this.isReady && activeExists) {
			const active =
				this.activeChildIndex === -1
					? entities[this.activeIndex]
					: entities[this.activeIndex][this.childItemIdentifier][this.activeChildIndex];
			this.select(active);
		}
	}

	get isReady() {
		const currentResult = this.results$.value;
		return !currentResult.isLoadingNewQuery && !currentResult.isLoadingMore && currentResult.error === undefined;
	}

	getIdentifier = (item: T): TypeaheadIdentifierValue => {
		return getTypeaheadIdentifierValue(item, this.itemIdentifier);
	};

	onItemMouseenter(index: number, childIndex: number) {
		this.activeIndex = index;
		this.activeChildIndex = childIndex;
	}

	onItemClick(element: T) {
		this.select(element);
	}

	onInputMouseDown(event: MouseEvent) {
		if (!this.hasInputFocus) {
			event.stopPropagation();
		}
	}

	onPopoverMouseDown(event: MouseEvent) {
		if (this.hasInputFocus) {
			event.preventDefault();
		}
	}

	onBodyClick = (ev: MouseEvent) => {
		const popoverWindow = (this.popover as any)._windowRef as ComponentRef<NgbPopoverWindow> | null;

		if (popoverWindow !== null) {
			if (popoverWindow.location.nativeElement.contains(ev.target)) {
				ev.preventDefault();
			} else if (!this.inputContainer.nativeElement.contains(ev.target as Node)) {
				this.close();
			}
		}
	};

	get hasInputFocus(): boolean {
		return this.input.nativeElement === document.activeElement;
	}

	open() {
		if (!this.isOpen$.value) {
			this.typeaheadPopoverService.addBodyClickHandlerForInstance(this.onBodyClick);
			this.popover.open();
			this.isOpen$.next(true);

			if (!this.resultsSubscription) {
				this.resultsSubscription = this.results$.subscribe(() => {
					/* keep alive */
				});
			}
		}
	}

	close() {
		if (this.isOpen$.value) {
			this.typeaheadPopoverService.removeBodyClickHandlerForInstance(this.onBodyClick);
			this.popover.close();
			this.isOpen$.next(false);
			this.searchText$.next('');
		}
	}

	applySearch() {
		return (searchText$: Observable<string>): Observable<TypeaheadSearchState<T>> =>
			combineLatest([this.search$, searchText$]).pipe(
				switchMap(([search, text]) => search(text).pipe(map((scrollingState) => ({ text, scrollingState })))),
				scan<{ text: string; scrollingState: InfiniteScrollingState<T> }, TypeaheadSearchState<T>, null>(
					(
						acc,
						{ text, scrollingState: { content, isLoading, error, totalElements } }
					): TypeaheadSearchState<T> => {
						const isLoadingNewQuery = isLoading && content.length === 0;
						return {
							isLoadingNewQuery,
							isLoadingMore: isLoading && !isLoadingNewQuery,
							latestResults: isLoadingNewQuery
								? acc?.latestResults
								: { text, entities: content, totalElements },
							error,
						};
					},
					null
				)
			);
	}

	select(value: T) {
		this.selected$.next(value);
		this._onChange(value);
		if (this.autoClosePopoverOnSelect) {
			this.close();
		}
	}

	popoverOption = (options: Partial<Options>) => {
		options = this.borderAvoidingPopperOptions(options);

		for (const modifier of options.modifiers || []) {
			if (modifier.name === 'offset' && modifier.options) {
				modifier.options.offset = () => [0, 2];
			}
		}
		options.onFirstUpdate = (state) => {
			if (state.elements?.arrow) {
				state.elements.arrow.style.display = 'none';
				state.elements.popper.style.padding = '0px';
			}
		};

		options.modifiers.push(minWidthReference);

		return options;
	};

	_onChange: (value: T) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		//default
	};

	registerOnChange(fn: (value: T) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	writeValue(value: T | null) {
		this.selected$.next(value);
	}
}

export const PAGE_SIZE = 50;

const buildInfiniteScrollingSearch = <T>(
	items: TypeaheadItems<T>,
	formatter: ObservableFormatter<T>,
	loadMore$: Observable<void>
): InfiniteScrollingSearch<T> => {
	if (typeof items === 'function') {
		return buildInfiniteScrollingSearchFromItemSearch(items, loadMore$);
	} else {
		return buildInfiniteScrollingSearchFromStaticItems(items, formatter);
	}
};

const buildInfiniteScrollingSearchFromItemSearch = <T>(
	search: TypeaheadItemSearch<T>,
	loadMore$: Observable<void>
): InfiniteScrollingSearch<T> => {
	return (query: string) =>
		loadMore$.pipe(
			startWith(null),
			exhaustScan<void, LoadingState<TypeaheadItemSearchPage<T>>, null>((previousPageState, _, pageNum) => {
				if (previousPageState?.data?.last) {
					return EMPTY;
				} else {
					return search(query, {
						size: PAGE_SIZE,
						page: pageNum,
					}).pipe(first(), trackLoadingState());
				}
			}, null),
			scan<LoadingState<TypeaheadItemSearchPage<T>>, InfiniteScrollingState<T>>(
				(infiniteScrollingState, loadingState) => ({
					content: loadingState.data
						? [...infiniteScrollingState.content, ...loadingState.data.content]
						: infiniteScrollingState.content,
					isLoading: loadingState.isLoading,
					totalElements: loadingState.data?.totalElements ?? -1,
					error: loadingState.error,
				}),
				{ content: [], isLoading: false, totalElements: -1 }
			)
		);
};

const buildInfiniteScrollingSearchFromStaticItems = <T>(
	items: TypeaheadStaticItems<T>,
	formatter: ObservableFormatter<T>
): InfiniteScrollingSearch<T> => {
	const itemsWithTexts$ = combineLatest(items.map((item) => formatter(item).pipe(map((text) => ({ item, text })))));
	return (query) =>
		itemsWithTexts$.pipe(
			map((entries) =>
				entries.filter(({ text }) => text.toLowerCase().includes(query.toLowerCase())).map(({ item }) => item)
			),
			map((matchingItems) => ({
				content: matchingItems,
				totalElements: matchingItems.length,
				isLoading: false,
			})),
			startWith({ content: [], isLoading: true, totalElements: -1 })
		);
};

export interface ResultTemplateContext<T> {
	result: T;
	term: string;
}

type InfiniteScrollingSearch<T> = (term: string) => Observable<InfiniteScrollingState<T>>;

interface InfiniteScrollingState<T> {
	content: T[];
	isLoading: boolean;
	error?: any;
	totalElements: number;
}

export interface TypeaheadSearchState<T> {
	latestResults?: Results<T>;
	isLoadingNewQuery: boolean;
	isLoadingMore: boolean;
	error?: any;
}

interface Results<T> {
	text: string;
	entities: T[];
	totalElements: number;
}
