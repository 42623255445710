import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { AngularEditor, withAngular } from 'slate-angular';
import { createEditor, Element } from 'slate';
import { findReportMultiLangEntry, richTextIsEmpty } from '@evasys/evainsights/shared/util';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { RichTextContent } from '@evasys/globals/evainsights/models/report-item';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { defaultRichTextBodyValue } from '@evasys/globals/evainsights/models/report-item-creator';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Component({
	selector: 'evainsights-rich-text',
	templateUrl: './rich-text.component.html',
})
export class RichTextComponent implements OnInit, OnChanges {
	@Input()
	@Required()
	id!: string;

	@Input()
	@Required()
	content!: RichTextContent;

	@Input({ required: true })
	set language(value: ReportLanguage) {
		this.language$.next(value);
	}

	language$ = new BehaviorSubject<ReportLanguage | undefined>(undefined);

	editor!: AngularEditor;

	bodyValue!: Observable<Element[]>;
	isEmptyText = false;

	constructor(readonly translocoService: TranslocoService) {
		// this is intentional
	}

	ngOnInit() {
		this.editor = withAngular(createEditor());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['content']) {
			this.bodyValue = this.getBody();
		}
	}

	getBody = (): Observable<Element[]> => {
		return this.language$.pipe(
			filter(isNotNullish),
			map((language) => {
				const bodyEntry = findReportMultiLangEntry(this.content.config.body, language.id);
				const value = bodyEntry?.value ?? defaultRichTextBodyValue;
				this.isEmptyText = richTextIsEmpty(value);
				return value;
			})
		);
	};
}
