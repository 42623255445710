import { Directive, inject, Injector, Input, OnInit, runInInjectionContext } from '@angular/core';
import { MultiLangService, ReportMultiLangService } from '@evasys/evainsights/shared/util';
import { ReportLanguageService } from '@evasys/evainsights/shared/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Directive({
	selector: '[evainsightsReportLanguageBoundary]',
	providers: [
		ReportMultiLangService,
		{
			provide: MultiLangService,
			useExisting: ReportMultiLangService,
		},
	],
})
export class ReportLanguageBoundaryDirective implements OnInit {
	reportLanguageService = inject(ReportLanguageService);
	reportMultiLangService = inject(ReportMultiLangService);
	injector = inject(Injector);

	// TODO: Remove?
	@Input() evainsightsReportLanguageBoundary = undefined;

	ngOnInit() {
		runInInjectionContext(this.injector, () => {
			toObservable(this.reportLanguageService.activeReportLanguage)
				.pipe(filter(isNotNullish), takeUntilDestroyed())
				.subscribe((language) => this.reportMultiLangService.setReportLanguage(language));
		});
	}
}
