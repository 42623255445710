import { FormMultiLang, ReportMultiLang, SystemMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { minBy } from 'lodash';
import { nonNullish } from '@evasys/globals/shared/helper/typeguard';

export const findSystemMultiLangEntry = <T>(multiLang: SystemMultiLang<T>, userLanguageCode: string) =>
	multiLang.find((l) => l.lang === userLanguageCode);

export const translateReportMultiLangString = (
	multiLang: ReportMultiLang<string>,
	reportLanguageId: number,
	fallback = ''
): string => findReportMultiLangEntry(multiLang, reportLanguageId)?.value || fallback;

export const findReportMultiLangEntry = <T>(multiLang: ReportMultiLang<T>, reportLanguageId: number) =>
	multiLang.find((l) => l.reportLanguageId === reportLanguageId);

export const selectByReportLanguage = <T>(formMultiLang: FormMultiLang<T>, reportLanguage: ReportLanguage): T => {
	if (formMultiLang.length === 0) {
		throw Error('Expected FormMultiLang with at least one translation');
	}

	const entry = findFormMultiLangEntryForReportLang(formMultiLang, reportLanguage);
	if (entry === undefined) {
		console.warn('Languages provided in form multi lang do not match those expected in the report language');
		const arbitraryFallback = minBy(formMultiLang, (translation) => translation.formLanguageId);
		return nonNullish(arbitraryFallback).value;
	}
	return entry.value;
};

export const findFormMultiLangEntryForReportLang = <T>(
	formMultiLang: FormMultiLang<T>,
	reportLanguage: ReportLanguage
) => {
	const reportLanguageFormLanguageIds = new Set(reportLanguage.formLanguages.map((fl) => fl.formLanguageId));
	return formMultiLang.find((langValue) => reportLanguageFormLanguageIds.has(langValue.formLanguageId));
};
