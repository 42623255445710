import { Injectable } from '@angular/core';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';

@Injectable({
	providedIn: 'root',
})
export abstract class SharedCoreConfiguration {
	public abstract api: {
		auth: string;
		user: (key: string) => string;
		users: string;
		translation: string;
		config: string;
		baseUrl: string;
		revokeTokenType: string;
	};

	public abstract auth: {
		clientId: string;
		clientSecret: string;
	};

	public abstract userMap: (apiData: any) => EvasysUserModel | ExternalIdUserModel;
}
