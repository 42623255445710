import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { parse } from '@tinyhttp/content-disposition';
import { downloadBlobContent } from '@evasys/globals/shared/helper/file';
import { nonNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({
	providedIn: 'root',
})
export class HttpResponseDownloadService {
	private static readonly HEADER_CONTENT_DISPOSITION = 'Content-Disposition';
	private static readonly PARAMETER_FILENAME = 'filename';

	download = (response: HttpResponse<Blob>, fallbackFileName?: string) => {
		downloadBlobContent(nonNullish(response.body), this.getFileName(response) ?? fallbackFileName);
	};

	getFileName = (response: HttpResponse<unknown>): string | null => {
		const contentDisposition = response.headers.get(HttpResponseDownloadService.HEADER_CONTENT_DISPOSITION);
		if (contentDisposition === null) {
			return null;
		}

		const fileName = parse(contentDisposition).parameters[HttpResponseDownloadService.PARAMETER_FILENAME];
		return typeof fileName === 'string' ? fileName : null;
	};
}
