import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { TopicWordcloudDatum } from '@evasys/globals/evainsights/models/report-item';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { TopicMetadata } from '@evasys/globals/evainsights/models/topic-modeling/topic-metadata.model';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';
import { TopicResponseSearchRequest } from '@evasys/globals/evainsights/models/search/TopicResponseSearchRequest';
import { Topic } from '@evasys/globals/evainsights/models/topic-modeling/topic.model';
import { TopicsSearchRequest } from '@evasys/globals/evainsights/models/search/TopicSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class TopicService {
	constructor(private http: HttpClient) {
		// This is intentional;
	}

	getTopicMetadata(topicId: string): Observable<TopicMetadata> {
		return this.http.get<TopicMetadata>(endpoints.topic.url({ topicId }));
	}

	getTopics(filter: TopicsSearchRequest = {}): Observable<Page<Topic>> {
		return this.http.post<Page<Topic>>(endpoints.topicsSearch.url(), filter);
	}

	getTopicWordcloudData(topicId: string): Observable<TopicWordcloudDatum[]> {
		return this.http.get<TopicWordcloudDatum[]>(endpoints.getWordcloudDataByTopicId.url({ topicId }));
	}

	searchTopicResponses(
		topicId: string,
		filter: TopicResponseSearchRequest = { text: '', page: 0, size: 10, onlyBuildResponses: false }
	): Observable<Page<TopicResponse>> {
		return this.http.get<Page<TopicResponse>>(endpoints.topicResponses.url({ topicId }), { params: { ...filter } });
	}

	deleteById(topicId: string): Observable<HttpResponse<unknown>> {
		return this.http.delete<HttpResponse<unknown>>(endpoints.topic.url({ topicId }));
	}

	patchTopicTitle(topicId: string, name: string): Observable<HttpResponse<unknown>> {
		return this.http.patch(
			endpoints.topic.url({ topicId }),
			{ name },
			{
				observe: 'response',
			}
		);
	}
}
