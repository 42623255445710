import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportFormat, getFormatInfo } from '@evasys/globals/evainsights/constants/data-types';
import { Options } from '@popperjs/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evainsights-export-menu',
	templateUrl: './export-menu.component.html',
})
export class ExportMenuComponent<F extends ExportFormat = ExportFormat> {
	@Input({ required: true })
	id!: string;

	@Input({ required: true })
	optionTranslocoKey!: string;

	@Input({ required: true })
	exportFormats!: F[];

	@Output()
	selectFormat = new EventEmitter<F>();

	/**
	 * Read-only value indicating whether the menu is currently open.
	 * The variable value gets updated as the menu is opened and closed through user interactions, but setting the value
	 * does not affect the menu state.
	 */
	isOpen = false;

	buttonDesign = ButtonDesignEnum;

	onClick(format: F) {
		this.selectFormat.emit(format);
	}

	getFormatExtension(format: F) {
		return getFormatInfo(format).extension;
	}

	popperOptions = (options: Partial<Options>) => {
		options.onFirstUpdate = (state) => {
			if (state.elements?.arrow) {
				state.elements.arrow.style.display = 'none';
			}
		};
		for (const modifier of options.modifiers || []) {
			if (modifier.name === 'offset' && modifier.options) {
				modifier.options['offset'] = () => [0, 0];
			}
		}
		return options;
	};
}
