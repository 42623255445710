import { inject, Injectable } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { map, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { hasText } from '@evasys/globals/shared/helper/string';
import { MultiLangService } from '../language/multi-lang.service';
import { FormElementDescriptorService, FormElementSelectTranslate } from './form-element-descriptor.service';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({
	providedIn: 'root',
})
export class UiFormElementDescriptorService {
	protected readonly translocoService = inject(TranslocoService);
	protected readonly multiLangService = inject(MultiLangService);
	protected readonly formElementDescriptorService = inject(FormElementDescriptorService);

	public readonly selectItemDescriptor = (item: Item): Observable<string> => {
		return this.formElementDescriptorService.selectItemDescriptor(item, this.getSelectTranslate());
	};

	private readonly getSelectTranslate = (): FormElementSelectTranslate => {
		return {
			key: (key) => this.translocoService.selectTranslate(key),
			form: (formMultiLang) =>
				this.multiLangService
					.translate(formMultiLang)
					.pipe(
						map((translation) => (isNotNullish(translation) && hasText(translation) ? translation : null))
					),
		};
	};
}
