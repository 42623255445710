import { Component, inject, Injector, Input, OnInit, runInInjectionContext } from '@angular/core';
import { ReportLanguageService } from '@evasys/evainsights/shared/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { getReportIdOrReportTemplateIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import {
	Report,
	ReportLanguage,
	ReportTemplate,
} from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { isEqual } from '@evasys/globals/shared/helper/object';
import { bufferWithLatestFrom } from '@evasys/shared/util';
import { isNotNullish, nonNullish } from '@evasys/globals/shared/helper/typeguard';

@Component({
	selector: 'evainsights-report-language-dropdown',
	templateUrl: './report-language-dropdown.component.html',
})
export class ReportLanguageDropdownComponent implements OnInit {
	@Input()
	set report(value: Report | ReportTemplate) {
		this.reportInput$.next(value);
	}

	//region Injections
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly reportLanguageService = inject(ReportLanguageService);
	private readonly facadeService = inject(ReportAndReportTemplateFacadeService);
	private injector = inject(Injector);
	//endregion
	//region Variables

	reportInput$ = new BehaviorSubject<Report | ReportTemplate | undefined>(undefined);
	reportFromStore$ = this.getReportFromStore();
	report$ = this.reportInput$.pipe(
		switchMap((reportInput) => (reportInput !== undefined ? of(reportInput) : this.reportFromStore$))
	);
	reportLanguages$ = this.report$.pipe(map((report) => report.languages));

	languageControl = new FormControl(this.reportLanguageService.activeReportLanguage()?.id);
	//endregion

	//region Events

	ngOnInit() {
		runInInjectionContext(this.injector, () => {
			this.forceSelectedFromAvailableReportLanguages();

			const reportLanguageById$ = this.reportLanguages$.pipe(
				map((langs) => new Map(langs.map((lang) => [lang.id, lang])))
			);

			this.languageControl.valueChanges
				.pipe(takeUntilDestroyed(), filter(isNotNullish), bufferWithLatestFrom(reportLanguageById$))
				.subscribe(([selectedReportLanguageId, reportLanguageById]) =>
					this.changeReportLanguage(nonNullish(reportLanguageById.get(selectedReportLanguageId)))
				);
		});
	}

	private getReportFromStore() {
		return combineLatest([this.activatedRoute.data, this.activatedRoute.paramMap]).pipe(
			takeUntilDestroyed(),
			switchMap(([routeData, paramMap]) => {
				const reportType = routeData[RouteDataParams.REPORT_TYPE];
				return this.facadeService.get(
					getReportIdOrReportTemplateIdFromParamMap(paramMap, reportType),
					reportType,
					EvasysLoadingStrategiesEnum.STATEONLY
				);
			}),
			filter(isNotNullish)
		);
	}

	/**
	 * Ensure the selected language always is one of the report's ReportLanguages.
	 */
	private forceSelectedFromAvailableReportLanguages() {
		combineLatest({
			selected: toObservable(this.reportLanguageService.activeReportLanguage),
			available: this.reportLanguages$,
		})
			.pipe(
				takeUntilDestroyed(),
				map(({ selected, available }) => ({
					selected,
					closestAvailable: this.reportLanguageService.findClosestReportLanguage(selected, available),
				})),
				filter(({ selected, closestAvailable }) => !isEqual(selected, closestAvailable))
			)
			.subscribe(({ closestAvailable }) => {
				this.languageControl.setValue(closestAvailable.id);
			});
	}

	//endregion

	//region Methods

	changeReportLanguage = (language: ReportLanguage): void => {
		this.reportLanguageService.changeActiveReportLanguage(language);
	};
	//endregion
}
