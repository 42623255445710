import { first, Observable, ObservedValueTupleFromArray, of, switchMap } from 'rxjs';
import { isNullish } from '../typeguards/common';

/**
 * Applies nullish coalescing to the values emitted by observables.
 *
 * The resulting observable emits the first non-nullish value from the observables,
 * or the values from the last observable if no non-nullish values are found.
 */
export const nullishCoalesceObservables = <O extends Observable<unknown>[]>(
	...observables: O
): NullishCoalesceObservables<O> => {
	if (observables.length === 0) {
		return of(undefined) as NullishCoalesceObservables<O>;
	} else if (observables.length === 1) {
		return observables[0] as NullishCoalesceObservables<O>;
	} else {
		const [head, ...rest] = observables;
		return head.pipe(
			switchMap((value) => (isNullish(value) ? nullishCoalesceObservables(...rest) : of(value)))
		) as NullishCoalesceObservables<O>;
	}
};

export type NullishCoalesce<A, B> = NonNullable<A> | B;
export type NullishCoalesceSequence<T extends unknown[]> = T extends [infer Head, ...infer Rest]
	? Rest extends []
		? Head
		: Head extends NonNullable<Head>
		? Head
		: NullishCoalesce<Head, NullishCoalesceSequence<Rest>>
	: undefined;

export type NullishCoalesceObservables<O extends Observable<unknown>[]> = Observable<
	NullishCoalesceSequence<ObservedValueTupleFromArray<O>>
>;

/**
 * Returns the first synchronously emitted value by an observable.
 *
 * The function throws an error if the observable does not emit synchronously.
 *
 * **Warning:** Using this function for RxJS interop with synchronous code is fragile and should only be considered
 * if you have complete control over the input observable and can ensure it emits synchronously on the default scheduler.
 * Otherwise, it may lead to subtle bugs, race conditions, or missed emissions.
 *
 * It is strongly recommended to rewrite the code to avoid using this function.
 * Prefer working with RxJS downstream to maintain proper reactivity, especially if the output ends up in the UI.
 * Alternatively, you can use `firstValueFrom`, which returns a Promise and is thus more robust when used with
 * observables that might include asynchronous operations.
 */
export const firstSyncValueFrom = <T>(obs: Observable<T>): T => {
	const captor = new ArgumentCaptor<[T]>();

	obs.pipe(first()).subscribe(captor.fn).unsubscribe();

	return captor.orElseThrow()[0];
};

class ArgumentCaptor<T extends unknown[]> {
	firstCall: { args: T } | null = null;

	public readonly fn = (...args: T) => {
		if (this.firstCall === null) {
			this.firstCall = { args };
		}
	};

	public readonly orElseThrow = (): T => {
		if (this.firstCall === null) {
			throw Error('The captor function has not been called yet');
		}

		return this.firstCall.args;
	};
}
