import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ModelId } from '@evasys/globals/evainsights/constants/model-ids';
import { Observable } from 'rxjs';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';
import { EVAINSIGHTS_SHARED_FEATURE_CONFIGURATION } from '../evainsights-shared-feature.configuration';

/**
 * Reads access tokens from the URL and writes them to the `Authorization` header.
 *
 * The interceptor is currently used for the engagement report reader and will later also be used for share URLs that
 * do not require authentication.
 */
@Injectable()
export class AccessTokenHeaderInterceptor implements HttpInterceptor {
	private static readonly HEADER_NAME = 'Authorization';

	private readonly config = inject(EVAINSIGHTS_SHARED_FEATURE_CONFIGURATION);
	private readonly router = inject(Router);

	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const isApiUrl = this.config.isApiUrl(req.url);
		const accessToken = this.getAccessToken();
		const newReq = isApiUrl && accessToken !== null ? this.withHeader(req, accessToken) : req;
		return next.handle(newReq);
	}

	private readonly getAccessToken = (): string | null => {
		const paramMap = this.getInnermostRouteSnapshot().paramMap;
		const reportId = paramMap.get(ModelId.REPORT_ID);
		return reportId === null || this.isNumeric(reportId) ? null : reportId;
	};

	private readonly getInnermostRouteSnapshot = () => {
		let route = this.router.routerState.snapshot.root;
		while (isNotNullish(route.firstChild)) {
			route = route.firstChild;
		}
		return route;
	};

	private readonly isNumeric = (s: string) => !Number.isNaN(Number(s));

	private readonly withHeader = (req: HttpRequest<unknown>, accessToken: string) =>
		req.clone({ headers: req.headers.set(AccessTokenHeaderInterceptor.HEADER_NAME, `Bearer ${accessToken}`) });
}
