import { Injectable } from '@angular/core';
import { TopicModel } from '@evasys/globals/evainsights/models/topic-modeling/topic-model.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { interval, Observable, startWith, switchMap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { TopicModelSearchRequest } from '@evasys/globals/evainsights/models/search/TopicModelSearchRequest';
import { TopicModelCalculationRequest } from '@evasys/globals/evainsights/models/topic-modeling/topic-model-calculation-request.model';
import { TopicModelInfo } from '@evasys/globals/evainsights/models/topic-modeling/topic-model-info.model';
import { RestService } from '../rest-service';
import {
	TopicModelAssignmentArguments,
	TopicModelAssignmentRemaining,
} from '@evasys/globals/evainsights/models/topic-modeling/topic-model-assignment.model';
import { ItemIdSurveyPeriodName } from '@evasys/globals/evainsights/models/survey/survey-period-info.entity';

@Injectable({
	providedIn: 'root',
})
export class TopicModelService
	implements RestService<TopicModel | TopicModelInfo, TopicModelSearchRequest | undefined>
{
	constructor(private http: HttpClient) {
		// This is intentional;
	}

	getSearchResult(filter: TopicModelSearchRequest = {}): Observable<Page<TopicModelInfo>> {
		return this.http.post<Page<TopicModelInfo>>(endpoints.topicModelInfosSearch.url(), filter);
	}

	getById(topicModelId: number): Observable<TopicModel> {
		return this.http.get<TopicModel>(endpoints.topicModel.url({ topicModelId }));
	}

	getInfoById(topicModelId: number): Observable<TopicModelInfo> {
		return this.http.get<TopicModelInfo>(endpoints.getTopicModelInfo.url({ topicModelId }));
	}

	deleteById(topicModelId: number) {
		return this.http.delete(endpoints.topicModel.url({ topicModelId }));
	}

	getItemSurveyPeriodNames(topicModelId: number): Observable<ItemIdSurveyPeriodName[]> {
		return this.http.get<ItemIdSurveyPeriodName[]>(endpoints.getItemIdSurveyPeriodNames.url({ topicModelId }));
	}

	calculateNewTopicModel(topicModelCalculationRequest: TopicModelCalculationRequest): Observable<TopicModelInfo> {
		return this.http.post<TopicModelInfo>(endpoints.calculateTopicModels.url(), topicModelCalculationRequest);
	}

	patchTopicModelTitle(topicModelId: number, title: string): Observable<HttpResponse<unknown>> {
		return this.http.patch(
			endpoints.topicModel.url({ topicModelId }),
			{ title },
			{
				observe: 'response',
			}
		);
	}

	listenById(topicModelId: number) {
		return interval(20000).pipe(
			startWith(undefined),
			switchMap(() => this.http.get<TopicModel>(endpoints.topicModel.url({ topicModelId })))
		);
	}

	listenBySearchRequest(filter: TopicModelSearchRequest = {}): Observable<Page<TopicModelInfo>> {
		return interval(20000).pipe(
			startWith(undefined),
			switchMap(() => this.http.post<Page<TopicModelInfo>>(endpoints.topicModelInfosSearch.url(), filter))
		);
	}

	assign({ topicModelId, ...body }: TopicModelAssignmentArguments) {
		return this.http.post<TopicModelAssignmentRemaining>(endpoints.assignTopicModel.url({ topicModelId }), body);
	}

	checkAssignments({ topicModelId, ...body }: TopicModelAssignmentArguments) {
		return this.http.post<TopicModelAssignmentRemaining>(
			endpoints.checkRemainingTopicAssignments.url({ topicModelId }),
			body
		);
	}
}
