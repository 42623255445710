<div
	[ngbPopover]="popoverContent"
	(shown)="isOpen = true"
	(hidden)="isOpen = false"
	[popperOptions]="popperOptions"
	placement="bottom-left bottom-right"
	popoverClass="popover-padding-0"
	container="body"
	class="d-inline-block"
>
	<ng-content></ng-content>
</div>

<ng-template #popoverContent>
	<div class="py-2">
		<evasys-button
			*ngFor="let chartExportFormat of exportFormats"
			[id]="'chartExportMenu-' + id + '-format-' + chartExportFormat"
			[design]="buttonDesign.LINK"
			class="border-0 w-100 text-start py-1"
			(click)="onClick(chartExportFormat)"
		>
			{{ optionTranslocoKey | transloco : { extension: chartExportFormat | modify : getFormatExtension } }}
		</evasys-button>
	</div>
</ng-template>
