import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReportService, RespondentFilterService } from '@evasys/evainsights/shared/core';
import { first, Subscription } from 'rxjs';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { ItemType } from '@evasys/globals/evainsights/constants/types';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { maxSelectedCheckboxes, minSelectedCheckboxes } from '@evasys/shared/util';
import { ReportFacadeService } from '@evasys/evainsights/stores/core';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Component({
	selector: 'evainsights-respondent-filter-modal',
	templateUrl: './respondent-filter-modal.component.html',
})
export class RespondentFilterModalComponent implements OnInit, OnDestroy {
	private readonly respondentFilterService = inject(RespondentFilterService);
	private readonly formBuilder = inject(FormBuilder);
	private readonly reportFacadeService = inject(ReportFacadeService);
	private readonly reportService = inject(ReportService);

	@Input({ required: true })
	public opened!: boolean;

	@Input({ required: true })
	public report!: Report;

	@Output()
	public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	readonly itemTypes = [ItemType.SINGLE_CHOICE, ItemType.MULTIPLE_CHOICE, ItemType.SCALA];
	selectedItemKey = 'selectedItem';
	form!: FormGroup;
	submitted = false;
	awaitBackendResponse = false;
	private selectedItemSubscription: Subscription | undefined = undefined;

	get selectedValuesFormArray(): UntypedFormArray {
		return this.form.controls['selectedValues'] as UntypedFormArray;
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			selectedItem: [null, Validators.required],
			selectedValues: new UntypedFormArray([]),
		});
		this.selectedItemSubscription = this.form.controls[this.selectedItemKey].valueChanges.subscribe((item) => {
			if (item !== null) {
				this.selectedValuesFormArray.clearValidators();
				const itemOptionsCount = item.itemOptions.length;
				item.itemOptions.forEach(() => this.selectedValuesFormArray.push(new FormControl(false)));
				this.selectedValuesFormArray.addValidators(minSelectedCheckboxes(1));
				this.selectedValuesFormArray.addValidators(maxSelectedCheckboxes(itemOptionsCount - 1));
				this.submitted = false;
			}
		});
	}

	addRespondentFilterItemOption() {
		this.submitted = true;
		if (this.form.valid && !this.awaitBackendResponse) {
			this.awaitBackendResponse = true;
			this.respondentFilterService
				.createNewRespondentFilterItemOptionByReportIdAndItemOptionIds(
					this.report.id,
					this.selectedItem.itemOptions
						.filter((itemOption, index) => this.selectedValuesFormArray.controls[index].value)
						.map((selectedItemOption) => selectedItemOption.id)
				)
				.pipe(first())
				.subscribe(() => {
					this.reportService
						.getById(this.report.id)
						.pipe(first())
						.subscribe((report) => {
							this.updateOneLocal(report);
						});
				});
		}
	}

	private updateOneLocal(report: Report) {
		this.reportFacadeService.updateOneLocal({
			id: report.id,
			changes: report,
		});
		this.resetSelectedItem();
		this.awaitBackendResponse = false;
		this.opened = false;
	}

	resetSelectedItem() {
		this.selectedValuesFormArray.clearValidators();
		this.form.get(this.selectedItemKey)?.setValue(null);
		this.selectedValuesFormArray.clear();
		this.selectedValuesFormArray.reset();
	}

	openedChangeEmit($event: boolean | undefined) {
		this.resetSelectedItem();
		this.openedChange.emit($event);
		this.submitted = false;
	}

	get selectedItem(): Item {
		return this.form.get(this.selectedItemKey)?.value;
	}

	ngOnDestroy(): void {
		if (this.selectedItemSubscription !== undefined) {
			this.selectedItemSubscription.unsubscribe();
		}
	}
}
