import { Component, computed, ElementRef, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ReportItem } from '@evasys/globals/evainsights/models/report-item';
import { ExportMenuComponent } from '../../export-menu/export-menu.component';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { ExportFormat } from '@evasys/globals/evainsights/constants/data-types';

@Component({
	selector: 'evainsights-report-item',
	templateUrl: './report-item.component.html',
	styleUrls: ['./report-item.component.scss'],
})
export class ReportItemComponent {
	@Input({ required: true })
	set reportItem(value: ReportItem) {
		this._reportItem.set(value);
	}

	@Input()
	editable = false;

	@Input()
	language?: ReportLanguage;

	@Input({ required: true })
	set isOpenQuestionExportPermitted(value: boolean) {
		this._isOpenQuestionExportPermitted.set(value);
	}

	@Output()
	selectExportFormat = new EventEmitter<ExportFormat>();

	@Output()
	configure = new EventEmitter<void>();

	@Output()
	delete = new EventEmitter<void>();

	@ViewChild('exportMenu')
	exportMenu?: ExportMenuComponent;

	_reportItem = signal<ReportItem>(null as unknown as ReportItem);
	_isOpenQuestionExportPermitted = signal<boolean>(null as unknown as boolean);
	isCard = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	bgClass = computed(() => (this.isCard() ? 'c-bg-white' : 'c-bg'));
	isConfigurable = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	exportFormats = computed((): ExportFormat[] => {
		switch (this._reportItem().content.config.visualizationType) {
			case VisualizationType.WORDCLOUD_RESPONSES:
				if (this._isOpenQuestionExportPermitted()) {
					return [ExportFormat.PNG, ExportFormat.XLSX, ExportFormat.CSV];
				} else {
					return [ExportFormat.PNG];
				}
			case VisualizationType.WORDCLOUD:
			case VisualizationType.BAR_CHART:
			case VisualizationType.LINE_CHART:
			case VisualizationType.PROFILE_LINE_CHART:
			case VisualizationType.RESPONSES:
				return [ExportFormat.PNG];
			default:
				return [];
		}
	});
	isDownloadable = computed(() => this.exportFormats().length > 0);

	buttonLinkDesign = ButtonDesignEnum.LINK;

	protected readonly visualizationType = VisualizationType;

	constructor(public elementRef: ElementRef) {
		/* this is intentional */
	}
}
