import { Item, ItemTypeId } from '../models/item/item.model';
import { ItemOption } from '../models/item/item-value-position.model';
import { maxBy, minBy } from 'lodash';
import { nonNullish } from '../../shared/helper/typeguard';

const getTypeIdPredicate = (trueTypeId: ItemTypeId) => (item: Item) => trueTypeId === item.typeId;

export const isScalaItem = getTypeIdPredicate(ItemTypeId.SCALA);
export const isOpenQuestionItem = getTypeIdPredicate(ItemTypeId.OPEN_QUESTION);
export const isSingleChoiceItem = getTypeIdPredicate(ItemTypeId.SINGLE_CHOICE);
export const isMultipleChoiceItem = getTypeIdPredicate(ItemTypeId.MULTIPLE_CHOICE);

export const isChoiceItem = (item: Item) => isSingleChoiceItem(item) || isMultipleChoiceItem(item);

export const hasAbstention = (item: Item) => item.itemOptions.some(isAbstention);
export const hasAnyItemAbstentions = (items: Item[] | undefined): boolean => {
	return items?.some((item) => hasAbstention(item)) ?? false;
};

export const abstentionPosition = 0;
export const isAbstentionPosition = (position: number) => position === abstentionPosition;
export const isAbstention = (itemOption: ItemOption) => isAbstentionPosition(itemOption.position);

export const isNotAbstention = (itemOption: ItemOption) => !isAbstention(itemOption);

export const sortItemOptionsByPosition = (a: ItemOption, b: ItemOption) =>
	Math.min(a.position, b.position) < 1 ? b.position - a.position : a.position - b.position;

export const getLeftmostItemOption = (options: ItemOption[]): ItemOption =>
	nonNullish(minBy(options.filter(isNotAbstention), (option) => option.position));

export const getRightmostItemOption = (options: ItemOption[]): ItemOption =>
	nonNullish(maxBy(options.filter(isNotAbstention), (option) => option.position));
