/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Size } from '../components/widgets/d3-base/d3-base.component';
import { axisBottom, axisLeft, NumberValue, ScaleBand, scaleOrdinal, ScaleOrdinal, select, Selection } from 'd3';
import {
	drawLegend,
	getResponseCountAxisChartStatistics,
	getStackedStatistics,
	matrixAt,
	nestedPositions,
	ResponseSegmentStatistics,
	SegmentPosition,
	Statistics,
	TrackStack,
	VisualizationStatistics,
	wrap,
	WrapOptions,
	WrapVerticalAlign,
} from './util';
import { Axis, AxisScale } from 'd3-axis';
import {
	axisFontSize,
	fontFamily,
	statisticsFontSize,
	verticalStatisticsSpaceBetween,
} from '@evasys/globals/evainsights/helper/charts/defaults';
import { getScaleColors } from '@evasys/globals/evainsights/helper/charts/color';
import { colorSchemes } from '@evasys/globals/evainsights/constants/color-schemes';
import {
	AxisChartConfig,
	AxisChartDataBase,
	BarChartContent,
	ColorSchemeType,
	ColorVisualDimension,
	ConditionalEvidence,
	CountLineChartContent,
	DataIndexDimensionType,
	DimensionMappingConfig,
	IndexDimension,
	Matrix,
	PeriodDomainType,
	ReportItemContentBase,
	VisualizationDimensionType,
} from '@evasys/globals/evainsights/models/report-item';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { groupBy, isEqual, keyBy } from 'lodash';
import { ReportMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import { namedZipShortest, zipShortest } from '@evasys/globals/shared/helper/array';
import { VisualStatisticsConfig } from '@evasys/globals/evainsights/models/report-item-creator';
import { AxisLabelPosition, Frame, Rect } from './util/frame';
import { distributeEvenly } from './util/distribute';
import { measureText, measureTexts } from './util/text/measure';
import { assertNotNullish, isNotNullish } from '@evasys/globals/shared/helper/typeguard';

type AxisChartContentBase = ReportItemContentBase<
	AxisChartConfig & {
		dimensionMappings: Array<DimensionMappingConfig<IndexDimension, ColorVisualDimension | { type: Key }>>;
	},
	AxisChartDataBase & { responseCounts?: Matrix }
>;

type ContentVisualizationDimension<Content extends AxisChartContentBase> =
	Content['config']['dimensionMappings'][number]['visualizations'][number];
type ContentVisualizationDimensionType<Content extends AxisChartContentBase> =
	ContentVisualizationDimension<Content>['type'];

export abstract class AxisChart<Content extends AxisChartContentBase> {
	protected constructor(
		protected host: HTMLElement,
		protected ctx: CanvasRenderingContext2D,
		protected content: Content,
		protected visualDimensions: Array<ContentVisualizationDimensionType<Content>>
	) {}

	createSvg({ size }: { size: Size }): Selection<SVGSVGElement, unknown, null, undefined> {
		select(this.host).selectAll('*').remove();

		return select(this.host)
			.append('svg')
			.attr('width', size.width)
			.attr('height', size.height)
			.attr('viewBox', [0, 0, size.width, size.height])
			.style('font-family', 'IBM Plex Sans, sans-serif');
	}

	/**
	 * Build the "frame" of the graph, i.e., all the elements that influence the size of main chart content.
	 * This method only inserts and measures the elements to determine a suitable frame config.
	 * It does not place the elements.
	 */
	buildFrame({
		svg,
		size,
		reportLanguageId,
		color,
		isValueAxisNumeric,
		textualStatisticsSize,
		visualStatisticsSize,
	}: {
		svg: Svg;
		size: Size;
		reportLanguageId: number;
		color: ColorDimensionSpecification;
		isValueAxisNumeric: boolean;
		textualStatisticsSize: number | undefined;
		visualStatisticsSize: number | undefined;
	}): Frame {
		const [xLabel, yLabel] = this.byOrientation(
			translateReportMultiLangString(this.content.config.axes.index.label, reportLanguageId),
			translateReportMultiLangString(this.content.config.axes.value.label, reportLanguageId)
		);

		const frame = new Frame(size, {
			hasXAxisLabel: xLabel !== '',
			yAxisLabelPosition: yLabel !== '' ? AxisLabelPosition.ABOVE : undefined,
			isValueAxisNumeric,
			isVertical: this.isVertical,
			legendHeight: this.showLegend ? 0 : undefined,
			textualStatisticsSize,
			visualStatisticsSize,
		});

		if (yLabel) {
			assertNotNullish(frame.yLabel);

			svg.append('text')
				.classed('yLabel', true)
				.attr('data-cy', 'yLabel')
				.attr('fill', '#000')
				.attr('font-size', axisFontSize)
				.attr('text-anchor', 'middle')
				.text(yLabel);

			const yLabelWidth = measureText(yLabel, this.ctx, { fontFamily, fontSize: axisFontSize });
			if (this.showLegend || yLabelWidth > frame.yLabel.width) {
				// y label does not fit above the y-axis
				frame.config.yAxisLabelPosition = AxisLabelPosition.BESIDES;
			}
		}

		if (xLabel) {
			assertNotNullish(frame.xLabel);

			svg.append('text')
				.classed('xLabel', true)
				.attr('data-cy', 'xLabel')
				.attr('fill', '#000')
				.attr('font-size', axisFontSize)
				.attr('text-anchor', 'middle')
				.attr('dominant-baseline', 'hanging')
				.text(xLabel);
		}

		// legend
		if (this.showLegend) {
			assertNotNullish(color.labels);
			assertNotNullish(frame.legend);

			const legendGroup = svg
				.append('g')
				.attr('transform', `translate(${frame.legend.left},${frame.legend.top})`);
			const { height: legendHeight } = drawLegend(legendGroup, {
				ctx: this.ctx,
				scale: color.scale,
				labels: color.labels,
				width: frame.legend.width,
			});

			frame.config.legendHeight = legendHeight;
		}

		return frame;
	}

	placeFrameElements({ svg, frame }: { svg: Svg; frame: Frame }) {
		if (frame.yLabel) {
			const yLabelText = svg.select<SVGTextElement>('.yLabel');
			if (frame.config.yAxisLabelPosition === AxisLabelPosition.ABOVE) {
				yLabelText.attr('x', frame.yLabel.center.x).attr('y', frame.yLabel.bottom);
			} else {
				yLabelText
					.call(wrap, { ctx: this.ctx, width: frame.yLabel.height, lines: 1 } satisfies WrapOptions)
					.attr('transform', `translate(${frame.yLabel.right},${frame.yLabel.center.y}) rotate(-90)`);
			}
		}

		if (frame.xLabel) {
			svg.select<SVGTextElement>('.xLabel')
				.attr('x', frame.xLabel.center.x)
				.attr('y', frame.xLabel.top)
				.call(wrap, { ctx: this.ctx, width: frame.xLabel.width, lines: 1 } satisfies WrapOptions);
		}
	}

	getAxesSpecifications({ size, body }: { size: Size; body: Rect }): AxisSpecifications {
		const [index, value] = this.byOrientation(
			<AxisSpecification>{
				property: { ax: 'x', size: 'width' },
				range: [body.left, body.right],
				axis: {
					painter: axisBottom,
					transform: `translate(0,${body.bottom})`,
					gridlineEnd: -body.height,
				},
				size: size.width,
			},
			<AxisSpecification>{
				property: { ax: 'y', size: 'height' },
				range: [body.bottom, body.top],
				axis: {
					painter: axisLeft,
					transform: `translate(${body.left},0)`,
					gridlineEnd: body.width,
				},
				size: size.height,
			}
		);

		return { index, value };
	}

	drawAxes({
		svg,
		frame,
		axisSpecifications: { index, value },
		indexAxis: indexAxisFactory,
		valueAxis: valueAxisFactory,
	}: {
		svg: Svg;
		frame: Frame;
		axisSpecifications: AxisSpecifications;
		indexAxis: () => Axis<number>;
		valueAxis: () => Axis<NumberValue>;
	}) {
		const indexAxis = indexAxisFactory().tickSizeOuter(0);
		const indexBandwidth = indexAxis.scale<ScaleBand<number>>().bandwidth();

		const indexLabelWrapOptions: WrapOptions = this.isVertical
			? {
					ctx: this.ctx,
					width: coalescingMin(indexBandwidth, frame.xAxisTickLabelSize.width),
					height: frame.xAxisTickLabelSize.height,
			  }
			: {
					ctx: this.ctx,
					width: frame.yAxisTickLabelSize.width,
					height: coalescingMin(indexBandwidth, frame.yAxisTickLabelSize.height),
					lines: 5,
					verticalAlign: WrapVerticalAlign.MIDDLE,
			  };

		const valueLabelWrapOptions: WrapOptions = this.isVertical
			? {
					ctx: this.ctx,
					...frame.yAxisTickLabelSize,
					lines: 5,
					verticalAlign: WrapVerticalAlign.MIDDLE,
			  }
			: { ctx: this.ctx, width: frame.xAxisTickLabelSize.width!, height: frame.xAxisTickLabelSize.height };

		svg.append('g')
			.attr('transform', index.axis.transform)
			.call(indexAxis)
			.selectAll<SVGTextElement, number>('.tick text')
			.attr('data-cy', (position) => 'indexAxis-label-' + position)
			.call(wrap, indexLabelWrapOptions);

		svg.append('g')
			.attr('transform', value.axis.transform)
			.call(valueAxisFactory())
			.call((g) => g.selectAll<SVGTextElement, unknown>('.tick text').call(wrap, valueLabelWrapOptions))
			.call((g) => g.select('.domain').remove())
			.call((g) =>
				g
					.selectAll('.tick line')
					.clone()
					.attr(`${index.property.ax}2`, value.axis.gridlineEnd)
					.attr('stroke-opacity', 0.1)
			);
	}

	protected buildVisualStatistics(
		statistics: Statistics,
		visualConfig: VisualStatisticsConfig
	): VisualizationStatistics {
		const visualStatistics: VisualizationStatistics = {
			arithmetic: undefined,
			quantile: undefined,
		};
		if ('arithmeticMean' in statistics) {
			const { showMean, showStandardDeviation, showMedian, showQuartile, showRange } = visualConfig;
			const { arithmeticMean, correctedSampleStandardDeviation, median, firstQuartile, thirdQuartile, min, max } =
				statistics;
			const quantile = {
				median: showMedian ? median : undefined,
				range: showQuartile ? { lower: firstQuartile, upper: thirdQuartile } : undefined,
				extrema: showRange ? { min, max } : undefined,
			};

			visualStatistics.arithmetic = showMean
				? {
						mean: arithmeticMean,
						standardDeviation: showStandardDeviation ? correctedSampleStandardDeviation : undefined,
				  }
				: undefined;

			visualStatistics.quantile = showMedian || showQuartile || showRange ? quantile : undefined;
		}

		return visualStatistics;
	}

	protected getPreparedSegmentStatistics(
		content: BarChartContent | CountLineChartContent
	): PreparedSegmentStatistics[] {
		return getResponseCountAxisChartStatistics(content).map(({ position, statistics }) => ({
			position,
			textual: statistics,
			visual:
				'median' in statistics.values &&
				'visual' in content.config.statistics &&
				content.config.statistics.visual
					? getStackedStatistics(
							this.buildVisualStatistics(statistics.values, content.config.statistics.visual)
					  )
					: undefined,
		}));
	}

	protected drawVerticalTextualStatistics(
		selection: Selection<
			SVGGElement,
			Array<{ position: SegmentPosition; textual: ResponseSegmentStatistics }>,
			null,
			undefined
		>,
		{ index, groupScale, top }: { index: AxisSpecification; groupScale: ScaleBand<number>; top: number },
		decimalFormat: string
	) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const chart = this;

		const minSegmentWidth = Math.min(
			...selection.datum().map((seg) => {
				const segmentRange = chart.getSegmentPositionIndexRange(seg.position, { index, groupScale });
				return segmentRange[1] - segmentRange[0];
			})
		);

		const fontSize = statisticsFontSize;
		const lineHeight = 1.1;
		const allTexts = selection.datum().flatMap((seg) => chart.getStatisticsTexts(seg.textual, decimalFormat));
		const allTextLengths = measureTexts(
			allTexts.map((v) => v.text),
			this.ctx,
			{ fontFamily, fontSize }
		);
		const byMeasure = groupBy(
			namedZipShortest({ text: allTexts, length: allTextLengths }),
			(statistic) => statistic.text.measure
		);
		const measureLengths = statisticTextMeasureOrder
			.map((measure) => {
				const lengths = byMeasure[measure]?.map((v) => v.length);
				return lengths !== undefined ? { measure, maxLength: Math.max(...lengths) } : null;
			})
			.filter(isNotNullish);

		const measureLines = distributeEvenly(
			measureLengths.map((m) => m.maxLength),
			minSegmentWidth,
			verticalStatisticsSpaceBetween
		).map((lineIndices) => lineIndices.map((measureIndex) => measureLengths[measureIndex].measure));

		// prettier-ignore
		selection
			.selectAll('text')
			.data((d) => d)
			.join('text')
				.attr('transform', (seg) => {
					const segmentRange = chart.getSegmentPositionIndexRange(seg.position, { index, groupScale });
					const segmentWidth = segmentRange[1] - segmentRange[0];
					const segmentCenter = segmentRange[0] + segmentWidth / 2;

					return `translate(${segmentCenter}, ${top})`;
				})
				.attr('font-family', fontFamily)
				.attr('font-size', fontSize)
				.attr('data-cy', 'd3-statistics')
			.selectAll('tspan')
			.data((seg) => {
				const textByMeasure = keyBy(chart.getStatisticsTexts(seg.textual, decimalFormat), (text) => text.measure);
				return measureLines.map(measureLine => measureLine.map(measure => textByMeasure[measure]).filter(isNotNullish));
			})
			.join('tspan')
				.attr('text-anchor', 'middle')
				.attr('x', 0)
				.attr('dy', `${lineHeight}em`)
			.selectAll('tspan')
			.data(line => line)
			.join('tspan')
				.attr('dx', (_, i) => i > 0 ? verticalStatisticsSpaceBetween : null)
				.text(text => text.text);

		return { height: measureLines.length * fontSize * lineHeight };
	}

	get hasTextualStatistics() {
		const numericalStatistics = this.content.config.statistics.numerical;
		return (
			numericalStatistics.showResponseCount ||
			numericalStatistics.showMedian ||
			numericalStatistics.showMean ||
			numericalStatistics.showStandardDeviation ||
			numericalStatistics.showAbstentionCount
		);
	}

	// prettier-ignore
	getStatisticsTexts(stats: ResponseSegmentStatistics, decimalFormat: string): StatisticText[] {
		const texts: StatisticText[] = [];
		const numericalStatistics = this.content.config.statistics.numerical;
		if(numericalStatistics.showResponseCount){
			texts.push({ measure: StatisticTextMeasure.RESPONSE_COUNT, text: `n: ${stats.evidence}`});
		}
		if ('median' in stats.values) {
			if(numericalStatistics.showMedian){
				texts.push({ measure: StatisticTextMeasure.MEDIAN, text: `md: ${stats.values.median}`.replace('.', decimalFormat)});
			}
			if(numericalStatistics.showMean){
				texts.push({ measure: StatisticTextMeasure.MEAN, text: `mw: ${stats.values.arithmeticMean.toFixed(2).replace('.', decimalFormat)}`});
			}
			if(numericalStatistics.showStandardDeviation){
				texts.push({ measure: StatisticTextMeasure.STANDARD_DEVIATION, text: `s: ${stats.values.correctedSampleStandardDeviation.toFixed(2)}`.replace('.', decimalFormat)});
			}
		}
		if (numericalStatistics.showAbstentionCount) {
			texts.push({ measure: StatisticTextMeasure.ABSTENTIONS, text: `e: ${stats.abstentions}`});
		}

		return texts;
	}

	protected getSegmentPositionIndexRange(
		segmentPosition: SegmentPosition,
		{ index, groupScale }: { index: AxisSpecification; groupScale: ScaleBand<number> }
	): [number, number] {
		const group = segmentPosition.find(isNotNullish);
		if (group === undefined) {
			return index.range;
		} else {
			const groupStart = groupScale(group)!;
			return [groupStart, groupStart + groupScale.bandwidth()];
		}
	}

	abstract get isVertical(): boolean;

	byOrientation<T>(a: T, b: T): [T, T] {
		// Return the values reordered depending on the orientation.
		// Does both ways: (category, value) -> (x, y) and (x, y) -> (category, value)
		return this.isVertical ? [a, b] : [b, a];
	}

	getColorScale(domain: number[]) {
		const scheme = this.getColorScheme();
		let colors = getScaleColors(scheme.scales[0], domain.length);
		if (scheme.type === ColorSchemeType.QUANTITATIVE && this.content.config.colors.reverse) {
			colors = [...colors].reverse();
		}

		return scaleOrdinal(domain, colors);
	}

	private getColorScheme() {
		const colorSchemeId = this.content.config.colors.colorSchemeId;
		const colorScheme = Object.values(colorSchemes).find((scheme) => scheme.id === colorSchemeId);

		if (!colorScheme) {
			console.warn('Could not find color scheme with id', colorSchemeId);
			return colorSchemes.qual;
		}

		return colorScheme;
	}

	getChartData(indexDomains: Domain[]): ChartDatum<ContentVisualizationDimensionType<Content>>[] {
		const normalize = this.getNormalizationFn();
		const dimensionMappings = this.getDimensionMapping();

		return nestedPositions(this.getValues())
			.filter(([index]) => zipShortest(indexDomains, index).every(([domain, index]) => domain.includes(index)))
			.map(([index, count]) => ({
				value: normalize(count, index),
				index,
				visual: this.mapIndexToVisual(index, dimensionMappings, 0),
			}))
			.filter(({ value }) => !Number.isNaN(value));
	}

	protected abstract getNormalizationFn(): (value: number, index: DimensionIndex[]) => number;

	protected getCountNormalizationFn(
		evidenceDimensionIndices: DimensionIndex[] | undefined,
		conditionalEvidences: ConditionalEvidence[]
	) {
		if (evidenceDimensionIndices === undefined) {
			return this.getNoNormalizationFn();
		}

		const conditionalEvidence = conditionalEvidences.find((ev) =>
			isEqual(ev.evidenceDimensionIndices, evidenceDimensionIndices)
		);
		assertNotNullish(conditionalEvidence);
		return this.getRelativeNormalizationFn(conditionalEvidence.evidence, evidenceDimensionIndices);
	}

	protected getNoNormalizationFn() {
		return (value: number) => value;
	}

	/**
	 * Builds a normalization function that normalizes each value relative to an evidence baseline.
	 * `P( Target | Evidence ) = Value[Index] / Evidence[Index[EvidenceDimensionIndices]]`
	 *
	 * @param evidence The baseline values
	 * @param evidenceDimensionIndices The dimension indices of the provided evidence within the full data matrix.
	 * An empty array `[]` indicates an `evidence` of type `number` that represents the global size `n`.
	 * An array containing all data dimension indices [0, 1, 2, ...] indicates that each value gets normalized individually.
	 */
	protected getRelativeNormalizationFn(evidence: Matrix, evidenceDimensionIndices: DimensionIndex[]) {
		return (value: number, index: Index) => {
			const indexEvidence = matrixAt(
				evidence,
				evidenceDimensionIndices.map((i) => index[i])
			);
			return value / indexEvidence;
		};
	}

	protected abstract getValues(): Matrix;

	protected getDimensionMapping(): DimensionMapping<ContentVisualizationDimensionType<Content>> {
		const map: DimensionMapping<ContentVisualizationDimensionType<Content>> = {};
		this.content.config.dimensionMappings.forEach((mapping, index) => {
			mapping.visualizations.forEach((visualization) => {
				map[visualization.type as ContentVisualizationDimensionType<Content>] = index;
			});
		});
		return map;
	}

	protected getNumberValueFormatter(
		target: NumberValueFormatterTarget,
		decimalFormat: string,
		hideZeroCounts?: boolean
	) {
		if (!this.isNormalized) {
			return (value: NumberValue) => {
				if (hideZeroCounts && value.valueOf() === 0) {
					return '';
				}
				return value.valueOf().toFixed(0);
			};
		} else {
			const fractionDigits = target === NumberValueFormatterTarget.AXIS ? 0 : 1;
			return (value: NumberValue) => {
				if (hideZeroCounts && value.valueOf() === 0) {
					return '';
				}
				return (100 * value.valueOf()).toFixed(fractionDigits).replace('.', decimalFormat) + '%';
			};
		}
	}

	protected abstract get isNormalized(): boolean;

	protected mapIndexToVisual<
		Mapping extends DimensionMapping<ContentVisualizationDimensionType<Content>>,
		Value,
		Fallback
	>(index: { [mappingIndex: number]: Value }, mapping: Mapping, fallback: Fallback) {
		const result: Partial<Record<ContentVisualizationDimensionType<Content>, Value | Fallback>> = {};
		for (const visualDimension of this.visualDimensions) {
			const indexDimension = mapping[visualDimension];
			if (indexDimension !== undefined) {
				const indexDimensionValue = index[indexDimension];
				assertNotNullish(indexDimensionValue);
				result[visualDimension] = indexDimensionValue;
			} else {
				result[visualDimension] = fallback;
			}
		}
		return result as { [dimension in ContentVisualizationDimensionType<Content>]: Value | Fallback };
	}

	protected getIndexDimensionDomainsAndLabels(reportLanguageId: number) {
		const periodById = keyBy(this.content.data.periods, (period) => period.id);

		const indicesAndLabels = this.content.config.dimensionMappings.map(
			(mapping, mappingIndex): Array<{ index: number; label: string }> => {
				if (
					mapping.data.type === DataIndexDimensionType.ITEM ||
					mapping.data.type === DataIndexDimensionType.ITEM_OPTION ||
					mapping.data.type === DataIndexDimensionType.TOPIC
				) {
					const domain: { exclude: boolean; name: ReportMultiLang<string>; hide?: boolean }[] =
						mapping.data.domain;

					const fullDomain = domain
						.filter((point) => !point.exclude && !point.hide)
						.map((point, index) => ({
							index,
							label: translateReportMultiLangString(point.name, reportLanguageId),
						}));

					return fullDomain;
				} else if (mapping.data.type === DataIndexDimensionType.PERIOD) {
					const domain = mapping.data.domain;
					const periodIds =
						domain.type === PeriodDomainType.CUSTOM
							? domain.periodIds
							: this.getIndexDomainFromData(mappingIndex).periodIds;

					return periodIds.map((periodId, index) => ({ label: periodById[periodId].name, index }));
				}

				// exhaustiveness check
				return mapping.data;
			}
		);

		return {
			domains: indicesAndLabels.map((dim) => dim.map((point) => point.index)),
			labels: indicesAndLabels.map((dim) => dim.map((point) => point.label)),
		};
	}

	private getIndexDomainFromData(mappingIndex: number) {
		const domainFromData = this.content.data.indexDomains?.find(
			(indexDomain) => indexDomain.dimensionIndex === mappingIndex
		);
		assertNotNullish(domainFromData);
		return domainFromData;
	}

	determineNice(spec: AxisSpecification) {
		return spec.size / 60;
	}

	protected get showLegend(): boolean {
		return this.getVisualizationDimensionByType(VisualizationDimensionType.COLOR)?.showLegend ?? false;
	}

	protected getVisualizationDimensionByType<T extends ContentVisualizationDimensionType<Content>>(
		type: T
	): Extract<ContentVisualizationDimension<Content>, { type: T }> | null {
		for (const mapping of this.content.config.dimensionMappings) {
			for (const visualization of mapping.visualizations) {
				if (visualization.type === type) {
					return visualization as Extract<typeof visualization, { type: T }>;
				}
			}
		}

		return null;
	}
}

const coalescingMin = (...values: (number | undefined)[]) => Math.min(...values.filter(isNotNullish));

export interface AxisSpecification {
	property: {
		ax: string;
		size: string;
	};
	range: [number, number];
	axis: {
		painter: <Domain>(scale: AxisScale<Domain>) => Axis<Domain>;
		transform: string;
		gridlineEnd: number;
	};
	size: number;
}

export interface AxisSpecifications {
	index: AxisSpecification;
	value: AxisSpecification;
}

export interface ColorDimensionSpecification {
	scale: ScaleOrdinal<number, string>;
	labels?: string[];
}

export type Key = string | number | symbol;

export type DimensionMapping<VisualDimension extends Key> = {
	[VD in VisualDimension]?: DimensionIndex;
};

export interface ChartDatum<VisualDimension extends Key> {
	value: number;
	index: Index;
	visual: Record<VisualDimension, number>;
}

type Svg = Selection<SVGSVGElement, unknown, null, undefined>;

export type Labels = string[];
export type Domain = number[];
export type Index = number[];
export type DimensionIndex = number;

export enum NumberValueFormatterTarget {
	AXIS,
	POINT,
}

export interface PreparedSegmentStatistics {
	position: SegmentPosition;
	visual?: TrackStack;
	textual?: ResponseSegmentStatistics;
}

enum StatisticTextMeasure {
	RESPONSE_COUNT,
	MEDIAN,
	MEAN,
	STANDARD_DEVIATION,
	ABSTENTIONS,
}

const statisticTextMeasureOrder: StatisticTextMeasure[] = [
	StatisticTextMeasure.RESPONSE_COUNT,
	StatisticTextMeasure.MEDIAN,
	StatisticTextMeasure.MEAN,
	StatisticTextMeasure.STANDARD_DEVIATION,
	StatisticTextMeasure.ABSTENTIONS,
];

interface StatisticText {
	measure: StatisticTextMeasure;
	text: string;
}
