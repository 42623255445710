import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { ReportItemService, ReportLanguageService, UiConfigService } from '@evasys/evainsights/shared/core';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ReportType, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { GridsterComponent, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { merge, pick } from 'lodash';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { EvainsightsGridsterConfig } from '@evasys/globals/evainsights/models/report/grid';
import { getGridsterConfig } from '@evasys/globals/evainsights/helper/grid';
import {
	disableGridsterPushBackSwappingItems,
	freezeGridsterRowHeight,
	makeGridsterSwapCompact,
} from '@evasys/evainsights/shared/util';
import { ReportItem, TopicWordcloudResponsesContent } from '@evasys/globals/evainsights/models/report-item';
import { debounceTime, distinctUntilChanged, first, map, Observable, Subject, Subscription, throttleTime } from 'rxjs';
import {
	ReportItemPlacement,
	ReportItemPlacementUpdate,
} from '@evasys/globals/evainsights/models/report/report-item-placement.model';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { NotificationService } from '@evasys/shared/core';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { ChartExportService } from '../../services/chart-export/chart-export.service';
import { isNotNullish, nonNullish } from '@evasys/globals/shared/helper/typeguard';
import { ExportFormat } from '@evasys/globals/evainsights/constants/data-types';

@Component({
	selector: 'evainsights-report-grid',
	templateUrl: './report-grid.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ReportGridComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
	readonly reportItemService = inject(ReportItemService);
	readonly router = inject(Router);
	readonly activatedRoute = inject(ActivatedRoute);
	readonly notificationService = inject(NotificationService);
	readonly translocoService = inject(TranslocoService);
	readonly reportAndReportTemplateFacadeService = inject(ReportAndReportTemplateFacadeService);
	readonly chartExportService = inject(ChartExportService);
	readonly reportLanguageService = inject(ReportLanguageService);
	readonly uiConfigService = inject(UiConfigService);

	@Input()
	report!: Report | ReportTemplate;

	@Input()
	editable = false;

	gridsterConfig!: EvainsightsGridsterConfig;

	reportType: ReportType = ReportType.REPORT;

	gridItemPositionUpdated = new Subject<boolean>();
	subscriptions: Subscription[] = [];

	@ViewChild('gridster', { static: true }) gridsterComponent!: GridsterComponent;

	ngOnInit() {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];

		disableGridsterPushBackSwappingItems();
		makeGridsterSwapCompact();

		// the itemChange event emitter of gridster fires many events at once. However, we do not want to send multiple requests to the backend.
		// Therefore, we add this Subject and debounce the calls to it.
		this.subscriptions.push(
			this.gridItemPositionUpdated.pipe(debounceTime(50)).subscribe(() => this.updateReportItemPlacements())
		);
	}

	ngAfterViewInit() {
		freezeGridsterRowHeight(this.gridsterComponent);

		this.subscriptions.push(
			this.createElementResizeObserver(this.gridsterComponent.el)
				.pipe(
					throttleTime(150),
					map((content) => content[0].contentRect.width),
					distinctUntilChanged()
				)
				.subscribe(() => {
					if (this.gridsterComponent.options.api?.resize) {
						this.gridsterComponent.options.api.resize();
					}
				})
		);
	}

	trackBy(index: number, item: ReportItem): number {
		return item.id;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['editable'] || changes['report']) {
			if (changes['editable']?.currentValue ?? this.editable) {
				this.gridsterConfig = merge({}, getGridsterConfig(), {
					draggable: {
						enabled: true,
					},
					resizable: {
						enabled: true,
					},
					gridType: GridType.ScrollVertical,
					disableScrollHorizontal: true,
					disableScrollVertical: false,
					scrollSpeed: 30,
					scrollSensitivity: 10,
					itemChangeCallback: this.itemChanged,
				} satisfies GridsterConfig);
			} else {
				this.gridsterConfig = merge({}, getGridsterConfig(), {
					draggable: {
						enabled: false,
					},
					resizable: {
						enabled: false,
					},
					gridType: GridType.ScrollVertical,
					disableScrollHorizontal: true,
					disableScrollVertical: false,
					scrollSpeed: 30,
					scrollSensitivity: 10,
				} satisfies GridsterConfig);
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	deleteReportItem(reportItem: ReportItem): void {
		// delete report item in the grid
		const originalReport = this.report;

		const report = {
			...this.report,
			reportItems: this.report.reportItems.filter((elem) => reportItem.id !== elem.id),
		};
		this.reportAndReportTemplateFacadeService.updateOneLocal(this.reportType, report);

		// delete the report item in the backend
		this.reportItemService
			.deleteReportItem(reportItem.id)
			.pipe(first())
			.subscribe({
				error: () => {
					this.notificationService.addNotification(
						NotificationEnum.ERROR,
						this.translocoService.translate('reportItem.error.deleteReportItem'),
						'deleteReportItemFailed',
						false
					);

					this.reportAndReportTemplateFacadeService.updateOneLocal(this.reportType, originalReport);
				},
			});
	}

	private createElementResizeObserver(element: HTMLElement): Observable<ResizeObserverEntry[]> {
		return new Observable((subscriber) => {
			let animationFrameId: number;
			const resizeObserver = new ResizeObserver((entries) => {
				if (animationFrameId) {
					cancelAnimationFrame(animationFrameId);
				}
				animationFrameId = requestAnimationFrame(() => {
					subscriber.next(entries);
				});
			});
			resizeObserver.observe(element);

			return () => resizeObserver.disconnect();
		});
	}

	configureItem(itemId: number): void {
		if (this.report) {
			const path =
				this.reportType === ReportType.REPORT
					? paths.getReportCreatorReportItemCreator.build({
							reportId: this.report.id,
							reportItemId: itemId,
					  })
					: paths.getReportTemplateCreatorReportTemplateItemCreator.build({
							reportId: this.report.id,
							reportItemId: itemId,
					  });

			this.router.navigate([path], { queryParamsHandling: 'preserve' });
		}
	}

	exportReportItemNode(reportItem: ReportItem, format: ExportFormat, element: Element) {
		if (format === ExportFormat.PNG) {
			this.chartExportService.exportChart(element, format);
		} else if (isTopicWordcloudResponsesReportItem(reportItem)) {
			const reportLanguage = nonNullish(this.reportLanguageService.activeReportLanguage());
			this.reportItemService.exportReportItem(reportItem.id, { format, reportLanguageId: reportLanguage.id });
		} else {
			throw Error(`Cannot export report item ${reportItem.id} as ${format}`);
		}
	}

	itemChanged: GridsterConfig['itemChangeCallback'] = (item) => {
		if (!isReportGridsterItem(item)) {
			throw Error('Gridster item is not a ReportGridsterItem');
		}

		item.reportItem.placement = this.getItemPlacement(item);
		this.gridItemPositionUpdated.next(true);
	};

	getItemPlacement = (item: GridsterItem): ReportItemPlacement => {
		return pick(item, 'rows', 'cols', 'x', 'y');
	};

	updateReportItemPlacements() {
		const reportItemPlacements: ReportItemPlacementUpdate[] = this.report.reportItems.map((reportItem) => {
			return {
				reportItemId: reportItem.id,
				placement: reportItem.placement,
			};
		});

		this.reportAndReportTemplateFacadeService.updateOneLocal(this.reportType, this.report);

		const patchMethod =
			this.reportType === ReportType.REPORT
				? this.reportItemService.patchReportItemPlacementsForReport
				: this.reportItemService.patchReportTemplateItemPlacementsForReportTemplate;

		patchMethod
			.call(this.reportItemService, this.report.id, reportItemPlacements)
			.pipe(first())
			.subscribe({
				error: () => {
					this.notificationService.addNotification(
						NotificationEnum.ERROR,
						this.translocoService.translate('reportItem.error.patchReportItemsPlacements'),
						'patchReportItemsPlacementsFailed',
						false
					);
				},
			});
	}

	getGridsterItem = (reportItem: ReportItem): ReportGridsterItem => {
		return {
			reportItem,
			...reportItem.placement,
			resizableHandles:
				reportItem.content.config.visualizationType === VisualizationType.DIVIDER
					? horizontalHandles
					: undefined,
		};
	};
}

const horizontalHandles: GridsterItem['resizableHandles'] = {
	n: false,
	ne: false,
	e: true,
	se: false,
	s: false,
	sw: false,
	w: true,
	nw: false,
};

const isReportGridsterItem = (gridsterItem: GridsterItem): gridsterItem is ReportGridsterItem => {
	return isNotNullish(gridsterItem['reportItem']);
};

const isTopicWordcloudResponsesReportItem = (
	reportItem: ReportItem
): reportItem is ReportItem<TopicWordcloudResponsesContent> =>
	reportItem.content.config.visualizationType === VisualizationType.WORDCLOUD_RESPONSES;

interface ReportGridsterItem extends GridsterItem {
	reportItem: ReportItem;
}
