import { inject, Pipe, PipeTransform } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { Observable } from 'rxjs';
import { UiFormElementDescriptorService } from '../form/ui-form-element-descriptor.service';

@Pipe({
	name: 'describeEntity',
})
export class DescribeEntityPipe implements PipeTransform {
	uiFormElementDescriptorService = inject(UiFormElementDescriptorService);

	transform(entity: Item): Observable<string> {
		return this.uiFormElementDescriptorService.selectItemDescriptor(entity);
	}
}
