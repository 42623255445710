import { ApplicationRef, EnvironmentInjector, inject, Injectable, NgZone, runInInjectionContext } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { UiConfigService } from '../common/ui-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getContextLanguageCode } from '@evasys/evainsights/shared/util';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	//region Injections
	private readonly translocoService = inject(TranslocoService);
	private readonly environmentInjector = inject(EnvironmentInjector);
	private readonly uiConfigService = inject(UiConfigService);
	private readonly ngZone = inject(NgZone);
	private readonly appRef = inject(ApplicationRef);
	//endregion

	constructor() {
		// add a util function for cypress, providing a fast and easy way to change the UI language
		(window as any).setUiLanguage = (language: string) => {
			this.ngZone.runOutsideAngular(() => {
				this.onUiConfigChange(language);
				this.appRef.tick(); // trigger rerender of the whole app
			});
		};
	}

	//region Changes

	private onUiConfigChange = (language: string) => {
		this.translocoService.setActiveLang(language);
	};
	//endregion

	//region Methods
	initialize() {
		runInInjectionContext(this.environmentInjector, () => {
			this.uiConfigService
				.getUiConfig()
				.pipe(
					takeUntilDestroyed(),
					map((uiConfig) => uiConfig.language)
				)
				.subscribe(this.onUiConfigChange);
		});
	}

	getActiveSystemLanguageCode(userLanguageCode?: string) {
		const activeLang = this.translocoService.getActiveLang();
		if (userLanguageCode === undefined) {
			return activeLang;
		}

		const contextLanguageCode = getContextLanguageCode(activeLang);
		const patchedSystemLanguageCode = `${userLanguageCode}_${contextLanguageCode}`;

		return this.translocoService.isLang(patchedSystemLanguageCode) ? patchedSystemLanguageCode : activeLang;
	}

	//endregion
}
