import { defer, filter, map, merge, Observable, of, withLatestFrom } from 'rxjs';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { AbstractControl, FormGroup, UntypedFormArray } from '@angular/forms';

export const pageContent = () => {
	return <T>(page: Observable<Page<T>>): Observable<T[]> => {
		return page.pipe(map((value) => value.content));
	};
};

/**
 * Like AbstractControl.valueChanges but it
 * - emits only valid and disabled values
 * - includes the initial value if it is valid or disabled
 * - for FormGroup and FormArray controls also includes disabled sub-controls
 */
export const validOrDisabledRawFormControlValueChanges = (control: AbstractControl): Observable<any> => {
	const status = merge(
		defer(() => of(control.status)),
		control.statusChanges
	);
	const value = merge(
		defer(() => of(isCompositeFormControl(control) ? control.getRawValue() : control.value)),
		formControlRawValueChanges(control)
	);

	return status.pipe(
		withLatestFrom(value),
		filter(([status]) => status === 'VALID' || status === 'DISABLED'),
		map(([, value]) => value)
	);
};

/**
 * Like AbstractControl.valueChanges but also includes values of disabled controls.
 * Solves the problem that a non-disabled form group (i.e. one with at least one non-disabled control) excludes disabled
 * controls from its `.value`.
 *
 * A form group like `new FormGroup({a: new FormControl('x'), b: new FormControl('y')})`
 * - has a value of `{a: 'x', b: 'y'}` if both form controls are non-disabled (e.g. valid or invalid)
 * - has a value of `{a: 'x'}` if the form control b is disabled
 * - has a value of `{a: 'x', b: 'y'}` if both a and b are disabled and the group itself is thus also disabled
 *
 * this method returns `{a: 'x', b: 'y'}` in all three cases
 */
export const formControlRawValueChanges = (control: AbstractControl): Observable<any> => {
	if (isCompositeFormControl(control)) {
		return control.valueChanges.pipe(map(() => control.getRawValue()));
	} else {
		return control.valueChanges;
	}
};

const isCompositeFormControl = (control: AbstractControl): control is UntypedFormArray | FormGroup => {
	return control instanceof UntypedFormArray || control instanceof FormGroup;
};
