import { WithNonNullishProperties } from '../types/with-required-properties.type';

export const isNullish = (v: unknown): v is null | undefined => v === null || v === undefined;
export const isNotNullish = <T>(v: T | null | undefined): v is T => !isNullish(v);

export function assertNotNullish<T>(v: T | undefined | null): asserts v is T {
	if (isNullish(v)) {
		throw Error('Value should not be undefined');
	}
}

export function nonNullish<T>(v: T | null | undefined): T {
	assertNotNullish(v);
	return v;
}

export const getPropertyNotNullishGuard =
	<P extends PropertyKey>(property: P) =>
	<T extends { [p in P]?: unknown }>(object: T): object is WithNonNullishProperties<T, P> =>
		isNotNullish(object[property]);
