<div class="container">
	<form [formGroup]="form">
		<evasys-textbox id="areaUiConfig-logoSrc" class="mt-3 mb-3" formControlName="logoSrc">Logo Src</evasys-textbox>

		<evasys-dropdown
			[formControls]="[form.controls.decimalFormat]"
			[id]="'areaUiConfig-decimalFormat'"
			class="mb-4"
		>
			<evasys-dropdown-primary evasysDropdownToggle>
				<span evasysDropdownLabel>Decimal Format</span>
				<span *evasysDropdownValue="let value = value" value>{{ value }}</span>
				<div *evasysDropdownMenu>
					<evasys-selector
						formControlName="decimalFormat"
						[forceSelection]="true"
						[id]="'areaUiConfig-decimalFormat'"
					>
						<evasys-selector-item *ngFor="let option of decimalFormatOptions" [value]="option">
							{{ option }}
						</evasys-selector-item>
					</evasys-selector>
				</div>
			</evasys-dropdown-primary>
		</evasys-dropdown>

		<evasys-textbox id="areaUiConfig-dateFormat" class="mt-3 mb-3" formControlName="dateFormat"
			>Date Format</evasys-textbox
		>
		<evasys-textbox id="areaUiConfig-timeFormat" class="mt-3 mb-3" formControlName="timeFormat"
			>Time Format</evasys-textbox
		>

		<ng-container formGroupName="language">
			<evasys-dropdown
				[formControls]="[form.controls.language.controls.user]"
				id="areaUiConfig-userLanguage"
				class="mb-4"
			>
				<evasys-dropdown-primary evasysDropdownToggle>
					<span evasysDropdownLabel>User Language</span>
					<span *evasysDropdownValue="let value = value" value>{{ value }}</span>
					<div *evasysDropdownMenu>
						<evasys-selector formControlName="user" [forceSelection]="true" [id]="'language-reportItem'">
							<evasys-selector-item *ngFor="let option of userLanguageOptions" [value]="option">
								{{ option }}
							</evasys-selector-item>
						</evasys-selector>
					</div>
				</evasys-dropdown-primary>
			</evasys-dropdown>
			<evasys-dropdown
				[formControls]="[form.controls.language.controls.context]"
				[id]="'areaUiConfig-contextLanguage'"
				class="mb-4"
			>
				<evasys-dropdown-primary evasysDropdownToggle>
					<span evasysDropdownLabel>Context Language</span>
					<span *evasysDropdownValue="let value = value" value>{{ value }}</span>
					<div *evasysDropdownMenu>
						<evasys-selector
							formControlName="context"
							[forceSelection]="true"
							[id]="'areaUiConfig-contextLanguage'"
						>
							<evasys-selector-item *ngFor="let option of contextLanguageOptions" [value]="option">
								{{ option }}
							</evasys-selector-item>
						</evasys-selector>
					</div>
				</evasys-dropdown-primary>
			</evasys-dropdown>
		</ng-container>

		<evasys-checkbox [id]="'permitOpenQuestionCsvExport'" formControlName="permitOpenQuestionCsvExport">
			Permit open question export
		</evasys-checkbox>
	</form>
</div>
