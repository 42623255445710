/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	AxisChart,
	AxisSpecification,
	ChartDatum,
	ColorDimensionSpecification,
	DimensionIndex,
	Labels,
	NumberValueFormatterTarget,
} from './axis';
import { RenderConfig } from '../components/widgets/d3-base/d3-base.component';
import { group, hsl, rollups, ScaleBand, scaleBand, scaleLinear, select, Selection } from 'd3';
import { BarChartOrientation } from '@evasys/globals/evainsights/constants/types';
import {
	addStatisticsDefinitions,
	avoid,
	AvoidDirection,
	drawStatisticsTrack,
	isRange,
	ResponseSegmentStatistics,
} from './util';
import {
	dataFontSize,
	fontFamily,
	roundingTolerance,
	statisticsFontSize,
} from '@evasys/globals/evainsights/helper/charts/defaults';
import {
	BarChartContent,
	BarChartVisualDimension,
	Matrix,
	VisualizationDimensionType,
} from '@evasys/globals/evainsights/models/report-item';
import { measureTexts } from './util/text/measure';
import { assertNotNullish, getPropertyNotNullishGuard, isNotNullish } from '@evasys/globals/shared/helper/typeguard';

export class BarChart extends AxisChart<BarChartContent> {
	constructor(host: HTMLElement, ctx: CanvasRenderingContext2D, content: BarChartContent) {
		super(host, ctx, content, barVisualDimensions);
	}

	// prettier-ignore
	render({ size, reportLanguageId, decimalFormat }: RenderConfig) {
		// The no-this-alias rule is meant to encourage the use of arrow functions instead of regular functions
		// which is however not an option here because we need the `this` binding provided by d3.
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const chart = this;

		const dimensionMapping = this.getDimensionMapping();
		const {labels: indexLabels, domains: indexDomains} = this.getIndexDimensionDomainsAndLabels(reportLanguageId);
		const visualLabels = this.mapIndexToVisual(indexLabels, dimensionMapping, undefined);
		assertNotNullish(visualLabels[VisualizationDimensionType.GROUP]);
		const visualDomains = this.mapIndexToVisual(indexDomains, dimensionMapping, [0]);
		const color: ColorDimensionSpecification = {
			scale: this.getColorScale(visualDomains[VisualizationDimensionType.COLOR]),
			labels: visualLabels[VisualizationDimensionType.COLOR],
		}

		const segmentStatistics = this.getPreparedSegmentStatistics(this.content);
		const visualStatisticsHeights = segmentStatistics.map(s => s.visual?.height).filter(isNotNullish);
		const visualStatisticsHeight = visualStatisticsHeights.length > 0 ? Math.max(...visualStatisticsHeights) : undefined;
		const textualStatisticsSegments = segmentStatistics.filter(getPropertyNotNullishGuard('textual'));

		const svg = this.createSvg({size});
		svg.append('defs').call(addStatisticsDefinitions);

		let textualStatisticsSize = this.hasTextualStatistics ? 0 : undefined;

		if (!this.isVertical && this.hasTextualStatistics) {
			// we have to measure the textual statistics width before building the frame as the text width influences the legend which gets drawn as part of the buildFrame method

			const statsContainer = svg.append('g').datum(textualStatisticsSegments.map(seg => seg.textual));
			textualStatisticsSize = this.drawHorizontalTextualStatistics(statsContainer, decimalFormat).width;
		}

		const frame = this.buildFrame({svg, size, reportLanguageId, color, isValueAxisNumeric: true, visualStatisticsSize: visualStatisticsHeight, textualStatisticsSize});

		// For horizontal charts, the index axis spec depends on the xAxis, the xLabel and the legend.
		// For vertical charts, the index axis spec depends on the yAxis and the yLabel.
		// In both these cases, the size of all these elements orthogonal to the index axis is already known and thus, so is the index axis spec.
		const {index} = this.getAxesSpecifications({size, body: frame.body});
		const {scale: groupScale, axis: groupAxis} = this.buildIndexAxisScale({
			index,
			domain: visualDomains[VisualizationDimensionType.GROUP],
			labels: visualLabels[VisualizationDimensionType.GROUP],
		});
		const stackScale = scaleBand(visualDomains[VisualizationDimensionType.STACK], [0, groupScale.bandwidth()]).paddingInner(0.1);

		if (this.hasTextualStatistics) {
			if (this.isVertical) {
				// write & place
				assertNotNullish(frame.textualStatistics);
				const statsContainer = svg.append('g').datum(textualStatisticsSegments);
				const statsSize = this.drawVerticalTextualStatistics(statsContainer, {index, groupScale, top: frame.textualStatistics.top}, decimalFormat);
				frame.config.textualStatisticsSize = statsSize.height;
			} else {
				// select & place
				svg.selectAll('.horizontalStatisticsSegment')
					.data(textualStatisticsSegments)
					.attr('transform', function (seg) {
						assertNotNullish(frame.textualStatistics);
						const segmentRange = chart.getSegmentPositionIndexRange(seg.position, { index, groupScale });
						const segmentCenter = (segmentRange[0] + segmentRange[1]) / 2;

						return `${select(this).attr('transform')} translate(${frame.textualStatistics.right}, ${segmentCenter})`
					})
			}
		}

		this.placeFrameElements({svg, frame});
		const {value} = this.getAxesSpecifications({size, body: frame.body});
		const axisSpecifications = {index, value};

		const barData: BarDatum[] = this.getChartData(indexDomains);
		const barPlacements = getBarPlacements(barData, groupScale, stackScale);

		const maxValue = Math.max(...barPlacements.map((p) => p.valueRange[1]));
		const valueNice = this.determineNice(value);
		const valueScale = scaleLinear([0, Math.max(0, maxValue - roundingTolerance)], value.range)
			.nice(valueNice);
		const valueAxisTickFormatter = this.getNumberValueFormatter(NumberValueFormatterTarget.AXIS, decimalFormat);
		const valueAxis = () => value.axis.painter(valueScale).ticks(valueNice).tickFormat(valueAxisTickFormatter);

		this.drawAxes({svg, frame, axisSpecifications, indexAxis: groupAxis, valueAxis});

		const absoluteBarPlacements = barPlacements.map(({stackPosition, stackSize, valueRange, datum}) => {
			const [v0, v1] = valueRange.map(valueScale);
			const barFill = color.scale(datum.visual[VisualizationDimensionType.COLOR]);
			const useLightLabel = hsl(barFill).l <= 0.5;
			return {
				stackPosition,
				stackSize,
				valuePosition: Math.min(v0, v1),
				valueSize: Math.abs(v0 - v1),
				datum,
				barFill,
				labelFill: useLightLabel ? '#fff' : '#000',
				labelStroke: useLightLabel,
			};
		});

		svg.append('g')
			.attr('data-cy', 'barChart-bars')
			.selectAll('rect')
			.data(absoluteBarPlacements)
			.join('rect')
			.attr('data-cy', (placement) => 'barChart-bars-bar-' + placement.datum.index)
			.attr(index.property.ax, plucker('stackPosition'))
			.attr(value.property.ax, plucker('valuePosition'))
			.attr(value.property.size, plucker('valueSize'))
			.attr(index.property.size, plucker('stackSize'))
			.attr('fill', plucker('barFill'));

		const isVertical = this.isVertical;
		const pointValueFormatter = this.getNumberValueFormatter(NumberValueFormatterTarget.POINT, decimalFormat,'hideZeroCounts' in this.content.config && this.content.config.hideZeroCounts);
		svg.append('g')
			.selectAll('g')
			.data(group(absoluteBarPlacements, (placement) => placement.stackPosition))
			.join('g')
			.call((stackTextGroup) =>
				stackTextGroup
					.selectAll('text')
					.data(([, stackBarPlacements]) => stackBarPlacements)
					.join('text')
					.text((placement) =>  pointValueFormatter(placement.datum.value))
					.attr('data-cy', (placement) => 'barChart-bars-bar-label-' + placement.datum.index)
					.attr(index.property.ax, (placement) => placement.stackPosition + placement.stackSize / 2)
					.attr(value.property.ax, (placement) => placement.valuePosition + placement.valueSize / 2)
					.attr('fill', plucker('labelFill'))
					.attr('stroke', (placement) => (placement.labelStroke ? placement.barFill : null))
					.attr('stroke-width', (placement) => (placement.labelStroke ? '2px' : null))
					.attr('paint-order', (placement) => (placement.labelStroke ? 'stroke' : null))
					.attr('text-anchor', 'middle')
					.attr('dominant-baseline', 'central')
					.attr('font-size', dataFontSize)
					.attr('font-weight', 'bold')
			)
			.each(function () {
				avoid(select(this).selectAll<SVGTextElement, null>('text'), {
					bounds: value.range,
					direction: isVertical ? AvoidDirection.VERTICAL : AvoidDirection.HORIZONTAL,
					padding: isVertical ? 0 : 3,
				});
			})

		svg.append('g')
			.selectAll('g')
			.data(segmentStatistics.filter(getPropertyNotNullishGuard('visual')))
			.join('g')
			.each(function (seg) {
				assertNotNullish(frame.visualStatistics);

				const g = select(this);

				const group = seg.position.find(isNotNullish);
				const shift = group === undefined ? 0 : groupScale(group)!
				const scale = getScaleLinearFromScaleBandCenters(group === undefined ? groupScale : stackScale);
				scale.domain([scale.domain()[0] + 1, scale.domain()[1] + 1]); // shift scale by 1 as our statistics are 1-indexed

				const distantFactor = chart.isVertical ? 1 : -1;
				const rotationTransform = chart.isVertical ? '' : ' rotate(90)';
				const outerPosition = chart.isVertical ? frame.visualStatistics.top : frame.visualStatistics.right;

				g
					.attr('data-cy', 'barChart-statisticsVisualization-' + (group ?? 'global'))
					.attr('transform', `translate(${chart.byOrientation(shift, outerPosition).join(', ')})`)
					.selectAll('g')
					.data(seg.visual.placedTracks)
					.join('g')
					.attr('transform', d => `translate(${chart.byOrientation(0, distantFactor * d.offset).join(', ')})` + rotationTransform)
					.datum(d => d.track)
					.call(drawStatisticsTrack(scale));
			})
	}

	protected override getValues(): Matrix {
		return this.content.data.responseCounts;
	}

	protected getNormalizationFn(): (value: number, index: DimensionIndex[]) => number {
		return this.getCountNormalizationFn(
			this.content.config.axes.value.normalization?.evidenceDimensionIndices,
			this.content.data.conditionalEvidences
		);
	}

	protected get isNormalized(): boolean {
		return this.content.config.axes.value.normalization !== null;
	}

	get isVertical(): boolean {
		return this.content.config.orientation === BarChartOrientation.VERTICAL;
	}

	buildIndexAxisScale({ index, labels, domain }: { index: AxisSpecification; domain: number[]; labels: Labels }) {
		const scale = scaleBand(this.isVertical ? domain : [...domain].reverse(), index.range).padding(0.1);
		const axis = () => index.axis.painter(scale).tickFormat((i) => labels[i]);
		return { scale, axis };
	}

	// prettier-ignore
	drawHorizontalTextualStatistics = (selection: Selection<SVGGElement, ResponseSegmentStatistics[], null, undefined>, decimalFormat: string) => {
		const fontSize = statisticsFontSize;

		const tspans = selection
			.selectAll('text')
			.data(d => d.map(seg => this.getStatisticsTexts(seg, decimalFormat)))
			.join('text')
				.classed('horizontalStatisticsSegment', true)
				.attr('font-family', fontFamily)
				.attr('font-size', fontSize)
				.attr('transform', texts => `translate(0, ${-texts.length / 2 * 1.1 * fontSize})`)
				.attr('text-anchor', 'end')
				.attr('data-cy', 'd3-statistics')
			.selectAll('tspan')
			.data(texts => texts)
			.enter()
			.append('tspan')
				.attr('x', 0)
				.attr('dy', '1.1em')
				.text((text) => text.text);

		return {
			width: Math.max(...measureTexts(tspans.data().map((text) => text.text), this.ctx, { fontFamily, fontSize }))
		};
	}
}

const getScaleLinearFromScaleBandCenters = (band: d3.ScaleBand<number>) => {
	const domain = band.domain();

	// linear scale requires a linear domain
	if (!isRange(domain)) {
		throw Error('Cannot build linear scale from band centers unless the domain is linear sequence of numbers');
	}

	const bw = band.bandwidth();
	return scaleLinear()
		.domain([domain[0], domain[domain.length - 1]])
		.range([band(domain[0])! + bw / 2, band(domain[domain.length - 1])! + bw / 2]);
};

function getBarPlacements(
	barData: BarDatum[],
	groupScale: ScaleBand<number>,
	stackScale: ScaleBand<number>
): BarPlacement[] {
	const d = rollups(
		barData,
		(data) => data,
		(datum) => datum.visual[VisualizationDimensionType.GROUP],
		(datum) => datum.visual[VisualizationDimensionType.STACK]
	);

	return d.flatMap(([group, groupData]) =>
		groupData.flatMap(
			([stack, stackData]) =>
				stackData.reduce(
					({ start, placements }, datum) => ({
						start: start + datum.value,
						placements: [
							...placements,
							{
								datum,
								stackPosition: groupScale(group)! + stackScale(stack)!,
								stackSize: stackScale.bandwidth(),
								valueRange: [start, start + datum.value] as [number, number],
							},
						],
					}),
					{ start: 0, placements: [] as BarPlacement[] }
				).placements
		)
	);
}

function plucker<T, P extends keyof T>(property: P): (o: T) => T[P] {
	return (o: T) => o[property];
}

const barVisualDimensions: Array<BarChartVisualDimension['type']> = [
	VisualizationDimensionType.COLOR,
	VisualizationDimensionType.GROUP,
	VisualizationDimensionType.STACK,
	VisualizationDimensionType.PATTERN,
	VisualizationDimensionType.COLOR,
	VisualizationDimensionType.COLUMN,
];

type BarDatum = ChartDatum<VisualizationDimensionType>;

interface BarPlacement {
	stackPosition: number;
	stackSize: number;
	valueRange: [number, number];
	datum: BarDatum;
}
