import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Item, ItemCollection, ItemInfoType, ScalaItemInfo } from '@evasys/globals/evainsights/models/item/item.model';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { MultiLangService, UiFormElementDescriptorService } from '@evasys/evainsights/shared/util';
import { ItemType } from '@evasys/globals/evainsights/constants/types';
import { FormService, ItemCollectionService } from '@evasys/evainsights/shared/core';
import {
	FilterAreaControl,
	FilterSelection,
} from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { TypeaheadItemPageRequest } from '@evasys/globals/shared/models/component/typeahead/typeahead.model';
import { Form } from '@evasys/globals/evainsights/models/form/form.model';
import { isSingleChoiceItem } from '@evasys/globals/evainsights/helper/item';
import { PaginatedItemCollectionSearchRequest } from '@evasys/globals/evainsights/models/search/PaginatedItemCollectionSearchRequest';
import { hasText } from '@evasys/globals/shared/helper/string';

@Component({
	selector: 'evainsights-items-search',
	templateUrl: './items-search.component.html',
})
export class ItemsSearchComponent implements OnChanges {
	//region Input & Output
	@Input()
	reportId!: number;
	@Input()
	surveys!: number[];
	@Input()
	forms!: number[];
	@Input()
	itemTypes!: ItemType[];
	@Input()
	submitted!: boolean;
	@Input()
	showResponseCounts!: boolean;
	@Input()
	control: AbstractControl | undefined;
	@Input()
	design: ChipTypeaheadDesignEnum = ChipTypeaheadDesignEnum.DEFAULT;
	@Input()
	isMultiSelect = true;

	filterAreaControls!: ReturnType<typeof this.getFilterAreaControls>;

	protected itemCollectionService = inject(ItemCollectionService);
	protected formService = inject(FormService);
	protected translocoService = inject(TranslocoService);
	protected multiLangService = inject(MultiLangService);
	protected uiFormElementDescriptorService = inject(UiFormElementDescriptorService);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['itemTypes'] || changes['forms']) {
			this.filterAreaControls = this.getFilterAreaControls();
		}
	}

	getFilterAreaControls() {
		const itemTypeFilterAreaControl = {
			id: 'itemTypes' as const,
			items: this.itemTypes,
			icon: { dark: 'text_code_check.svg', light: 'text_code_check_deactivated.svg' },
			identifier: (itemType: ItemType) => itemType,
			name: 'items.itemTypes',
			formatter: (itemType) => this.translocoService.selectTranslate('items.itemType.' + itemType),
			emptyResultsText: 'items.noneFound',
		} satisfies FilterAreaControl<ItemType>;

		const formFilterAreaControl = {
			id: 'forms' as const,
			items: (name, pageRequest) => this.formService.getSearchResult({ ids: this.forms, name, ...pageRequest }),
			icon: { dark: 'desktop.svg', light: 'desktop_deactivated.svg' },
			name: 'filterArea.filter.form',
			identifier: 'id',
			formatter: 'shortName',
			emptyResultsText: 'filterArea.filter.form.error.noneFound',
		} satisfies FilterAreaControl<Form>;

		return this.itemTypes.length > 1 ? [itemTypeFilterAreaControl, formFilterAreaControl] : [formFilterAreaControl];
	}

	resultFormatter = (result: ItemCollection | Item) =>
		'formShortName' in result
			? this.multiLangService.translate(result.text, '')
			: this.uiFormElementDescriptorService.selectItemDescriptor(result);

	getResultId = (result: ItemCollection | Item) =>
		'formShortName' in result ? `itemCollection-${result.id}` : `item-${result.id}`;

	getPlaceholderTranslocoKey = (result: ItemCollection | Item) =>
		'formShortName' in result ? 'itemCollection.withoutName' : 'item.withoutText';

	searchItemCollections = (
		description: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: TypeaheadItemPageRequest
	): Observable<Page<ItemCollection>> => {
		return this.itemCollectionService.searchItemCollections({
			reportId: this.reportId,
			itemTextName: description,
			surveys: this.surveys,
			forms: this.getFormsFilter(filter),
			types: 'itemTypes' in filter && filter.itemTypes.length ? filter.itemTypes : this.itemTypes,
			page: pageRequest.page,
			size: 20,
			withInfo: true,
			withItemOptions: true,
			withResponseCountInInfo: this.showResponseCounts,
		} satisfies PaginatedItemCollectionSearchRequest);
	};

	private getFormsFilter(filter: FilterSelection<typeof this.filterAreaControls>): number[] {
		if ('forms' in filter && filter.forms.length) return filter.forms.map((form) => form.id);

		// if no filter for forms is set, return a distinct list of all forms
		return this.forms.filter((value, index, array) => array.indexOf(value) === index);
	}

	getContextInfos = (result: Item | ItemCollection): Observable<string[]> => {
		if ('formShortName' in result) {
			return of([result.formShortName]);
		} else if ('info' in result && result.info) {
			const info = result.info;
			switch (info.type) {
				case ItemInfoType.CHOICE:
					return combineLatest([
						this.translocoService.selectTranslate('item.info.itemOptionCount', {
							count: info.itemOptionCount,
						}),
						this.translocoService.selectTranslate(
							'items.itemType.' +
								(isSingleChoiceItem(result) ? ItemType.SINGLE_CHOICE : ItemType.MULTIPLE_CHOICE)
						),
					]).pipe(map(([itemOptionInfoText, itemTypeName]) => [itemOptionInfoText, itemTypeName]));

				case ItemInfoType.SCALA:
					return combineLatest([
						this.getScalaItemOptionInfoText(info),
						this.translocoService.selectTranslate('items.itemType.' + ItemType.SCALA),
					]);

				case ItemInfoType.OPEN_QUESTION:
					if (this.showResponseCounts) {
						return combineLatest([
							this.translocoService.selectTranslate('item.info.responseCount', {
								count: info.responseCount,
							}),
							this.translocoService.selectTranslate('items.itemType.' + ItemType.OPEN_QUESTION),
						]).pipe(map(([responsesInfoText, itemTypeName]) => [responsesInfoText, itemTypeName]));
					} else {
						return this.translocoService
							.selectTranslate('items.itemType.' + ItemType.OPEN_QUESTION)
							.pipe(map((itemTypeName) => [itemTypeName]));
					}
			}
		} else {
			return of([]);
		}
	};

	getScalaItemOptionInfoText(info: ScalaItemInfo): Observable<string> {
		return combineLatest([
			this.translocoService.selectTranslate('item.info.itemOptionCount', { count: info.itemOptionCount }),
			this.multiLangService.translate(info.leftPoleText, ''),
			this.multiLangService.translate(info.rightPoleText, ''),
		]).pipe(
			switchMap(([countInfo, left, right]) =>
				hasText(left) || hasText(right)
					? this.translocoService
							.selectTranslate('item.info.poleTexts', {
								left: left,
								right: right,
							})
							.pipe(map((poleTextInfo) => `${countInfo} (${poleTextInfo})`))
					: of(countInfo)
			)
		);
	}
}
