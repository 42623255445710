import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { filter, map, of } from 'rxjs';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { ResponsesContent } from '@evasys/globals/evainsights/models/report-item/content/responses-report-item-content.model';
import { FilterService } from '@evasys/evainsights/shared/core';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ResponseSentiment } from '@evasys/globals/evainsights/models/search/response.model';
import { buildPage } from '@evasys/globals/evainsights/helper/pagination';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Component({
	selector: 'evainsights-responses-widget',
	templateUrl: './responses-widget.component.html',
})
export class ResponsesWidgetComponent extends WidgetBaseComponent implements OnChanges {
	@Input({ required: true })
	content!: ResponsesContent;

	@Input()
	reportItemStatus: ReportItemContentStatus = ReportItemContentStatus.PROCESSED;

	protected filterService = inject(FilterService);

	filterAreaControls = [this.filterService.sentiments];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['content']) {
			this.titleObservables.next(
				this.reportLanguage$.pipe(
					filter(isNotNullish),
					map((reportLanguage) =>
						translateReportMultiLangString(this.content.config.title, reportLanguage.id)
					)
				)
			);
		}
	}

	getSearchResponses = (
		query: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: { size: number; page: number }
	) => {
		return of(this.getFilteredResponses(query, filter, pageRequest));
	};

	getFilteredResponses = (
		query: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: { size: number; page: number }
	): Page<ResponseSentiment> => {
		let result = this.content.data.responseSentiment;
		if (query !== '') {
			const regExpQuery = RegExp(query, 'i');
			result = result.filter((obj) => regExpQuery.exec(obj.response.value));
		}
		if (filter['sentiments'].length > 0) {
			result = result.filter((obj) => filter['sentiments'].includes(obj.sentiment));
		}
		return buildPage(result, { number: pageRequest.page - 1, size: pageRequest.size });
	};
	protected readonly ReportItemContentStatus = ReportItemContentStatus;
}
