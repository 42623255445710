import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { filter, map } from 'rxjs';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { WordcloudContent } from '@evasys/globals/evainsights/models/report-item';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { UiConfigService } from '@evasys/evainsights/shared/core';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Component({
	selector: 'evainsights-wordcloud-widget',
	templateUrl: './wordcloud-widget.component.html',
})
export class WordcloudWidgetComponent extends WidgetBaseComponent implements OnChanges {
	uiConfigService = inject(UiConfigService);

	@Input()
	id = '';

	@Input()
	@Required()
	content!: WordcloudContent;

	@Input()
	reportItemStatus?: ReportItemContentStatus;

	@Input()
	fontSizeRange: [number, number] = [12, 60];

	uiConfig = this.uiConfigService.getUiConfig();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['content']) {
			this.titleObservables.next(
				this.reportLanguage$.pipe(
					filter(isNotNullish),
					map((reportLanguage) => this.getTitleText(reportLanguage))
				)
			);
		}
	}

	getTitleText(reportLanguage: ReportLanguage): string {
		const fallback = 'topic' in this.content.data ? this.content.data.topic.name : '';
		return translateReportMultiLangString(this.content.config.title, reportLanguage.id, fallback);
	}
}
