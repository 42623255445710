import { Injectable } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { FormMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import {
	getLeftmostItemOption,
	getRightmostItemOption,
	isChoiceItem,
	isNotAbstention,
	isScalaItem,
} from '@evasys/globals/evainsights/helper/item';
import { combineLatest, map, Observable, of } from 'rxjs';
import { firstSyncValueFrom, nullishCoalesceObservables } from '@evasys/globals/evainsights/helper/rxjs';
import { nonNullish } from '@evasys/globals/shared/helper/typeguard';

/**
 * Constructs element descriptors for use-cases where a concise textual description is required, such as a multiselect chip.
 *
 * The methods include suitable fallback texts to cover those cases in which the primary text is empty.
 */
@Injectable({ providedIn: 'root' })
export class FormElementDescriptorService {
	private static readonly SAMPLE_ITEM_OPTION_COUNT = 3;

	public readonly getItemDescriptor = (item: Item, translate: FormElementTranslate): string => {
		return firstSyncValueFrom(this.selectItemDescriptor(item, this.toSelectTranslate(translate)));
	};

	public readonly selectItemDescriptor = (
		item: Item,
		selectTranslate: FormElementSelectTranslate
	): Observable<string> => {
		return nullishCoalesceObservables(
			selectTranslate.form(item.text),
			this.selectItemDescriptorFromOptions(item, selectTranslate),
			selectTranslate.key('item.withoutText')
		);
	};

	private readonly selectItemDescriptorFromOptions = (
		item: Item,
		selectTranslate: FormElementSelectTranslate
	): Observable<string | null> => {
		if (isScalaItem(item)) {
			return combineLatest({
				left: selectTranslate.form(getLeftmostItemOption(item.itemOptions).text),
				right: selectTranslate.form(getRightmostItemOption(item.itemOptions).text),
			}).pipe(
				map(({ left, right }) => {
					if (left === null && right === null) {
						return null;
					} else {
						return `${left} - ${right}`.trim();
					}
				})
			);
		} else if (isChoiceItem(item)) {
			const nonAbstentionOptions = item.itemOptions.filter(isNotAbstention);
			const sampleOptions = nonAbstentionOptions.slice(0, FormElementDescriptorService.SAMPLE_ITEM_OPTION_COUNT);
			// the evasys form editor strictly requires a non-empty text for all added item options
			return combineLatest(
				sampleOptions.map((itemOption) => nonNullish(selectTranslate.form(itemOption.text)))
			).pipe(
				map((sampleOptionTexts) => {
					if (nonAbstentionOptions.length > sampleOptions.length) {
						sampleOptionTexts = [...sampleOptionTexts, '…'];
					}

					return sampleOptionTexts.join(', ');
				})
			);
		} else {
			return of(null);
		}
	};

	private readonly toSelectTranslate = (translate: FormElementTranslate): FormElementSelectTranslate => ({
		key: this.withObservableResult(translate.key),
		form: this.withObservableResult(translate.form),
	});

	private readonly withObservableResult = <Fn extends (...args: never[]) => unknown>(
		fn: Fn
	): ObservableResult<Fn> => {
		return (...args) => of(fn(...args) as ReturnType<Fn>);
	};
}

export interface FormElementTranslate {
	key: (key: string) => string;
	form: (formMultiLang: FormMultiLang<string>) => string | null;
}

export type FormElementSelectTranslate = {
	[K in keyof FormElementTranslate]: ObservableResult<FormElementTranslate[K]>;
};

type ObservableResult<Fn extends (...args: never[]) => unknown> = (
	...args: Parameters<Fn>
) => Observable<ReturnType<Fn>>;
