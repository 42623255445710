import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core';
import { ProductMenuItem, Tabitem } from '@evasys/shared/ui';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { TopmenuTranslationModel } from '@evasys/globals/shared/models/translation/topmenu-translation.model';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { AuthEndpointService } from '../../../../../core/src/lib/services/auth/auth-endpoint.service';
import { Router } from '@angular/router';
import { filter, map, Observable, startWith } from 'rxjs';

@Component({
	selector: 'evainsights-top-menu',
	templateUrl: './top-menu.component.html',
})
export class TopMenuComponent implements OnInit {
	@Input()
	@Required()
	translation: TopmenuTranslationModel | undefined;

	@Input()
	logo!: string;

	@Input()
	logoAnchor: string | undefined;

	@Input()
	username = '';

	@Input()
	role = '';

	@Input()
	value = '';

	@Input()
	menuItems: Tabitem<string>[] = [];

	@Output()
	menuResponse = new EventEmitter<string>();

	@Input()
	roleItems: Map<UserRightsEnum, string> | null = new Map<UserRightsEnum, string>();

	@Output()
	roleResponse = new EventEmitter<any>();

	@Output()
	logoutResponse = new EventEmitter<void>();

	@Output()
	helpResponse = new EventEmitter<void>();

	private readonly router = inject(Router);
	public readonly authEndpointService = inject(AuthEndpointService);

	public collapsable = false;
	public collapsed = true;

	productsObservable: Observable<ProductMenuItem[]>;

	activeMenuTabItemIndex!: Observable<number>;

	@HostListener('window:resize')
	private determineSearchbarCollapse(): void {
		this.collapsable = window.innerWidth <= 991;
	}

	constructor() {
		this.productsObservable = this.authEndpointService.getAvailableProducts();
	}

	ngOnInit(): void {
		this.activeMenuTabItemIndex = this.router.events.pipe(
			startWith(null),
			map(() => this.getActiveTabItemIndex()),
			filter((value) => value >= 0),
			startWith(0)
		);
	}

	public onLogoClicked() {
		if (this.logoAnchor) {
			this.router.navigate([this.logoAnchor]);
		}
	}

	asString = (value: unknown) => value as string;

	private getActiveTabItemIndex(): number {
		return this.menuItems.findIndex((tabItem: Tabitem<string>) => {
			let includesHref = false;
			tabItem.href
				?.subscribe((href) => {
					includesHref = this.router.url.includes(href);
				})
				.unsubscribe();
			return includesHref;
		});
	}
}
