<ng-container *ngLet="logo$ | async as logo">
	<ng-container *ngLet="authenticated$ | async as authenticated">
		<evasys-notification></evasys-notification>
		<ng-container *ngIf="(showMainContentOnly | async) === false">
			<evainsights-top-menu
				*ngIf="authenticated && logo"
				(logoutResponse)="onLogout()"
				(roleResponse)="onUserRightChange($event)"
				(helpResponse)="onHelpClicked()"
				[menuItems]="menuTabItems"
				[roleItems]="(user | async)?.userRights | userRightsDesignation"
				[role]="userRightDesignation[(user | async)?.activeRight] | transloco"
				[username]="(user | async)?.fullName"
				[logo]="logo"
				[logoAnchor]="logoAnchorPath"
			></evainsights-top-menu>
		</ng-container>
		<div class="col h-100 p-0 d-flex flex-column flex-nowrap c-content-min-width">
			<div class="c-mh-0px flex-grow-1 align-self-stretch">
				<router-outlet [class.opacity-0]="!logo || authenticated"></router-outlet>
			</div>
			<evasys-footer [footerText]="'footer' | transloco">
				<evasys-legal-notice [id]="'dataPrivacyInformation'"></evasys-legal-notice>
			</evasys-footer>
		</div>
		<evasys-loading-overlay
			class="c-z-index-1-important"
			[backdropColorClass]="'c-bg opacity-100'"
			[loading]="!logo && authenticated"
		></evasys-loading-overlay>
	</ng-container>
</ng-container>
