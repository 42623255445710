export enum ExportFormat {
	PNG = 'PNG',
	CSV = 'CSV',
	XLSX = 'XLSX',
}

export type ChartExportFormat = ExportFormat.PNG;

export interface FormatInfo {
	mimeType: string;
	extension: string;
}

const formatInfos: Record<ExportFormat, FormatInfo> = {
	[ExportFormat.PNG]: { mimeType: 'image/png', extension: 'png' },
	[ExportFormat.CSV]: { mimeType: 'text/csv', extension: 'csv' },
	[ExportFormat.XLSX]: {
		mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		extension: 'xlsx',
	},
};

export function getFormatInfo(format: ExportFormat): Readonly<FormatInfo> {
	return formatInfos[format];
}
