import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Endpoints, EvasysEndpoint, EvasysPlusEndpoint } from '@evasys/globals/evasys/models/business/endpoints.model';
import { ProductMenuItem } from '@evasys/shared/ui';
import { AuthUserServiceHelperService } from '@evasys/evainsights/shared/core';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({
	providedIn: 'root',
})
export class AuthEndpointService {
	private readonly productsSubject = new BehaviorSubject<ProductMenuItem[]>([]);

	constructor(private http: HttpClient, readonly authUserServiceHelperService: AuthUserServiceHelperService) {
		// This is intentional;
	}

	private getEvasysProduct(endpoint: EvasysEndpoint): ProductMenuItem {
		return {
			id: 'administration',
			name: 'topmenu.productMenu.administration',
			url: endpoint.evasysUrl + '?forwardinfo=' + endpoint.forwardKey,
		};
	}
	private getEvasysPlusProducts(endpoint: EvasysPlusEndpoint | null): ProductMenuItem[] {
		return endpoint !== null
			? endpoint.targets
					.filter((target: string) => target !== 'qualitymanagement')
					.map(
						(target: string) =>
							({
								id: target,
								name: 'topmenu.productMenu.' + target,
								url: endpoint.baseUrl + endpoint.sessionId + '?target=' + target,
							} satisfies ProductMenuItem)
					)
			: [];
	}

	public refreshProductUrls = async () => {
		const evaXData = await firstValueFrom(this.http.get<Endpoints>(endpoints.endpoints.url()));
		this.productsSubject.next(
			[
				evaXData.evasysEndpoint !== null ? this.getEvasysProduct(evaXData.evasysEndpoint) : undefined,
				...this.getEvasysPlusProducts(evaXData.evasysPlusEndpoint),
			].filter(isNotNullish)
		);
	};

	public getAvailableProducts() {
		return this.productsSubject;
	}
}
