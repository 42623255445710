import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { filter, map } from 'rxjs';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { TopicWordcloudContent, TopicWordcloudResponsesContent } from '@evasys/globals/evainsights/models/report-item';
import { VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';

@Component({
	selector: 'evainsights-wordcloud-responses-widget',
	templateUrl: './wordcloud-responses-widget.component.html',
})
export class WordcloudResponsesWidgetComponent extends WidgetBaseComponent implements OnChanges {
	@Input()
	@Required()
	content!: TopicWordcloudResponsesContent;

	@Input()
	reportItemStatus: ReportItemContentStatus = ReportItemContentStatus.PROCESSED;

	wordcloudContent?: TopicWordcloudContent;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['content']) {
			this.titleObservables.next(
				this.reportLanguage$.pipe(
					filter(isNotNullish),
					map((reportLanguage) => this.getTitleText(reportLanguage))
				)
			);

			this.wordcloudContent = {
				config: {
					...this.content.config,
					visualizationType: VisualizationType.WORDCLOUD,
				},
				data: this.content.data.wordcloudContent,
			};
		}
	}

	getTitleText(reportLanguage: ReportLanguage): string {
		return translateReportMultiLangString(
			this.content.config.title,
			reportLanguage.id,
			this.content.data.wordcloudContent.topic.name
		);
	}
}
