import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, debounceTime, distinctUntilChanged, map, Observable, share, startWith, Subject } from 'rxjs';
import { SearchPostRequest } from '@evasys/globals/evainsights/models/search/SearchRequest';
import { EvasysSortingModel } from '@evasys/globals/shared/models/general/evasys-sorting.model';
import { SearchSelection } from '@evasys/globals/evainsights/models/search/search-selection.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { SearchRequestParamService } from './search-request-param.service';
import { asArray } from '../../../../../util/src/lib/general/as-array';
import { isEqual } from '@evasys/globals/shared/helper/object';
import { Sentiment } from '@evasys/globals/evainsights/constants/types';
import { getEnumValue } from '@evasys/globals/shared/helper/enum';
import { latestData, trackLoadingStates } from '@evasys/shared/util';
import { isNotNullish } from '@evasys/globals/shared/helper/typeguard';
import { PaginatedPostSort } from '@evasys/globals/evainsights/models/search/PaginatedSearchPostRequest';

export class DashboardTableDataProvider<T, K extends string> {
	constructor(
		readonly search: (filter: FulltextSearchRequest<K>) => Observable<Page<T>>,
		readonly paramService: SearchRequestParamService,
		readonly activatedRoute: ActivatedRoute,
		readonly searchKey: K
	) {
		const urlSortParam = this.activatedRoute.snapshot.queryParams['sort'];
		if (urlSortParam && urlSortParam.length > 1) {
			const urlSortParts = urlSortParam.split(',');
			this.sortField = urlSortParts[0];
			this.sortOrder = urlSortParts[1];
		}
		const urlSearchTextParam = this.activatedRoute.snapshot.queryParams['searchText'];
		if (urlSearchTextParam?.length) {
			this.searchText = urlSearchTextParam;
		}

		this.listenToSearchTextChanges();
	}

	sortOrder: string | undefined;
	sortField: string | undefined;
	searchText: string | undefined;

	filterParams: Observable<FulltextSearchRequest<K>> = this.activatedRoute.queryParams.pipe(
		map((params) => {
			const searchRequest = defaultSearchRequestParams(params);

			const textRequest: { [key in K]?: string } = {};
			if (params['searchText'] !== undefined) textRequest[this.searchKey] = params['searchText'];

			return { ...searchRequest, sort: toPostSort(params['sort']), ...textRequest };
		}),
		distinctUntilChanged(isEqual)
	);

	reload$ = new Subject<void>();

	search$ = new Subject<string>();

	requests = combineLatest([this.filterParams, this.reload$.pipe(startWith(null))]).pipe(
		map(([filterParams]) => this.search(filterParams)),
		trackLoadingStates(),
		share()
	);

	loading = this.requests.pipe(
		startWith({ isLoading: true }),
		map((state) => state.isLoading)
	);

	latestPage: Observable<Page<T>> = this.requests.pipe(latestData());

	nextPage(page: number) {
		this.paramService.updateParams({ page });
	}

	reload() {
		this.reload$.next();
	}

	setPageSize(size: number) {
		this.paramService.updateParams({ size });
	}

	sortPage(sort: string) {
		this.paramService.updateParams({ sort });
	}

	setSearchText(value: string) {
		this.search$.next(value);
	}

	private listenToSearchTextChanges() {
		this.search$.pipe(debounceTime(200)).subscribe((searchText) => {
			this.paramService.updateParams({
				searchText: searchText.length ? searchText : undefined,
			});
		});
	}

	onSort({ columnField, sortOrderAscending }: EvasysSortingModel) {
		this.sortOrder = sortOrderAscending ? 'asc' : 'desc';
		this.sortField = columnField;
		const sortingParams = `${this.sortField},${this.sortOrder}`;
		this.sortPage(sortingParams);
	}

	isSortAscending(sortOrder: string) {
		return sortOrder === 'asc';
	}
}

export type FulltextSearchRequest<K extends string> = SearchPostRequest & { [key in K]?: string };

const toPostSort = (sortParam: unknown): PaginatedPostSort | undefined => {
	if (typeof sortParam !== 'string') {
		return undefined;
	}
	const strings = sortParam.split(',');
	if (strings.length !== 2) {
		throw new Error('Unexpected sort: ' + sortParam);
	}
	const [property, direction] = strings;
	if (direction !== 'asc' && direction !== 'desc') {
		throw new Error('Invalid direction argument: ' + direction);
	}
	return { property, direction };
};

export const defaultSearchRequestParams = (params: Params): SearchPostRequest => ({
	page: Number(params['page']) - 1 || 0,
	size: Number(params['pageSize']) || 10,
	units: getParamAsStringArray(params['unitId']).map(Number),
	programmes: getParamAsStringArray(params['programmeId']).map(Number),
	periods: getParamAsStringArray(params['periodId']).map(Number),
	forms: getParamAsStringArray(params['formId']).map(Number),
	participationEvents: getParamAsStringArray(params['participationEventId']).map(Number),
	leaders: getParamAsStringArray(params['leaderId']).map(Number),
	sentiments: getParamAsStringArray(params['sentimentId'])
		.map((str) => getEnumValue(Sentiment, str))
		.filter(isNotNullish),
});

const getParamAsStringArray = (param: string | string[] | undefined) => (param === undefined ? [] : asArray(param));

export const searchRequestFilterParams = (params: Params, searchSelection: SearchSelection): SearchPostRequest => ({
	page: Number(params['page']) || 0,
	size: Number(params['pageSize']),
	units: searchSelection.units.map((unit) => unit.key),
	programmes: searchSelection.programmes.map((programm) => programm.key),
	periods: searchSelection.periods.map((period) => period.key),
	forms: searchSelection.forms.map((form) => form.id),
	participationEvents: searchSelection.participationEvents.map((participationEvent) => participationEvent.key),
	leaders: searchSelection.leaders.map((leader) => leader.key),
});
