export enum ItemType {
	ALL_QUANTITATIVE = 'All',
	SCALA = 'ITEM_SCALA',
	OPEN_QUESTION = 'ITEM_OPEN_QUESTION',
	SINGLE_CHOICE = 'ITEM_SINGLE_CHOICE',
	MULTIPLE_CHOICE = 'ITEM_MULTIPLE_CHOICE',
}

export enum AnalysisType {
	WORD_FREQUENCY = 'WORD_FREQUENCY',
	RESPONSES = 'RESPONSES',
	TOPIC = 'TOPIC',
	QUANTITATIVE = 'QUANTITATIVE',
	STRUCTURAL_ELEMENTS = 'STRUCTURAL_ELEMENTS',
}

export enum DataBasisType {
	QUANTITATIVE_ANALYSIS = 'QUANTITATIVE_ANALYSIS',
	QUALITATIVE_ANALYSIS = 'QUALITATIVE_ANALYSIS',
	STRUCTURAL_ELEMENTS = 'STRUCTURAL_ELEMENTS',
}

export enum VisualizationType {
	WORDCLOUD = 'WORDCLOUD',
	BAR_CHART = 'BAR_CHART',
	LINE_CHART = 'LINE_CHART',
	RADAR_CHART = 'RADAR_CHART',
	WORDCLOUD_RESPONSES = 'WORDCLOUD_RESPONSES',
	TEXTUAL_COMPARISON = 'TEXTUAL_COMPARISON',
	KEYWORD_CARD = 'KEYWORD_CARD',
	RICH_TEXT = 'RICH_TEXT',
	DIVIDER = 'DIVIDER',
	PROFILE_LINE_CHART = 'PROFILE_LINE_CHART',
	RESPONSES = 'RESPONSES',
}

export enum BarChartVisualizationType {
	STACKED = 'STACKED',
	GROUPED = 'GROUPED',
}

export enum BarChartGroupBy {
	ITEM = 'ITEM',
	ITEM_OPTION = 'ITEM_OPTION',
}

export enum BarChartOrientation {
	VERTICAL = 'VERTICAL',
	HORIZONTAL = 'HORIZONTAL',
}

export enum ReportType {
	REPORT = 'REPORT',
	TEMPLATE = 'TEMPLATE',
}

export enum NumberOfTopics {
	FEW = '5',
	SOME = '10',
	MANY = '25',
}

export enum Sentiment {
	POSITIVE = 'POSITIVE',
	NEUTRAL = 'NEUTRAL',
	NEGATIVE = 'NEGATIVE',
}

export enum TopicModelStatus {
	OPEN = 'OPEN',
	SUBMITTED = 'SUBMITTED',
	QUEUED = 'QUEUED',
	BUILDING = 'BUILDING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	FAILED = 'FAILED',
	NO_RESPONSES = 'NO_RESPONSES',
}
export enum ValueAxisUnit {
	COUNT = 'COUNT',
	PERCENT = 'PERCENT',
}

export enum Language {
	DE = 'de',
	EN = 'en',
	ES = 'es',
	FR = 'fr',
}

export enum RadioButtonGroupDesign {
	ICONS_ONLY = 'ICONS_ONLY',
	BOX_TEXT = 'BOX_TEXT',
}
