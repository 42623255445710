import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	FormMultiLang,
	FormMultiLangEntry,
	ReportMultiLangEntry,
} from '@evasys/globals/evainsights/models/common/multi-lang';
import { MultiLangService } from './multi-lang.service';
import { nonNullish } from '@evasys/globals/shared/helper/typeguard';

@Injectable({ providedIn: 'root' })
export class TranslocoMultiLangService extends MultiLangService {
	protected override findFormMultiLangEntry<T>(
		formMultiLang: FormMultiLang<T>
	): Observable<FormMultiLangEntry<T> | undefined> {
		return this.fromUserLang(
			(userLang) =>
				formMultiLang.find((translation) => translation.userLanguage === userLang) ??
				nonNullish(formMultiLang.find((translation) => translation.isMain))
		);
	}

	protected override findReportMultiLangEntry<T>(): Observable<ReportMultiLangEntry<T> | undefined> {
		throw Error('Cannot translate report multi lang outside the context of a report');
	}
}
